<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    v-if="info"
    class="info-item"
  >
    <span
      v-if="info.subject"
      class="info-item__subject text_s color_secondary"
    >
      {{ info.subject }}
    </span>
    <span
      v-if="info.message"
      :class="[
        'info-item__message text_s color_secondary',
        { 'info-item__message-3': !info.subject },
      ]"
    >
      {{ info.message }}
    </span>
    <span
      v-else-if="info.emptyState"
      class="color_tertiary text_s"
    >
      <i>
        {{ info.emptyState }}
      </i>
    </span>
    <template v-if="info.email_type">
      <p class="info-item__message text_s color_secondary">
        {{ contentType }}
      </p>
      <p class="info-item__message text_s color_secondary">
        {{ $t('campaignsPage.editor.steps.step.info.viewButton') }}
      </p>
    </template>
    <template v-if="info.actions">
      <span
        v-for="action in info.actions"
        :key="action"
        class="info-item__message text_s color_secondary"
      >
        {{ action | normalizeNameByAction }}
      </span>
    </template>
  </div>
</template>
<script>
  import { normalizeNameByActionMixin } from '@/mixins/normalizeNameByActionMixin';

  export default {
    name: 'InfoItem',
    mixins: [normalizeNameByActionMixin],
    props: {
      info: {
        type: Object,
        default: null,
        require: true,
      },
    },
    computed: {
      contentType () {
        return this.$t(
          'campaignsPage.editor.steps.step.info.emailContent', {
            type: this.normalizeEmailType(this.info.email_type),
          }
        );
      },
    },
    methods: {
      normalizeEmailType (emailType) {
        switch (emailType) {
        case 'work':
          return this.$t('campaignsPage.editor.steps.step.info.emailType.work');

        case 'predicted':
          return this.$t('campaignsPage.editor.steps.step.info.emailType.enriched');

        default:
          return this.$t('campaignsPage.editor.steps.step.info.emailType.personal');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .info-item {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    &__message {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
      &-3 {
        -webkit-line-clamp: 3; /* number of lines to sho */
      }
    }
  }
</style>
