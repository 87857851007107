<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="send-sms">
    <div
      v-if="$route.name !== 'my-templates'"
      class="send-sms__header"
    >
      <generate-prompt-steps
        :message="innerMessage"
        :generate="generatedMessage"
        :action-id="actionId"
        class="send-sms__generate-prompt-step"
        :class="{'send-sms__generate-prompt-step_open' : showInputFeature}"
        @update:generate="onGenerateMessage"
      />
      <div class="send-sms__header-from">
        <span class="text_s color_secondary">From:</span>
        <default-select
          :items="numbersList"
          :read-only="true"
          input-size="xs"
          :value="selectedPhoneNumber?.name"
          data-closable="sendEmailStepFrom"
          validation-string="required"
          input-placeholder="Unselect"
          @select="updateVoxPhone"
        />
      </div>
      <div class="send-sms__header-to">
        <span class="text_s color_secondary">To:</span>
        <default-select
          :items="phoneTypes"
          input-size="xs"
          class="ml-2"
          :value="selectedPhoneType?.name"
          data-closable="sendEmailStepTo"
          validation-string="required"
          :read-only="true"
          input-placeholder="Unselect"
          @select="updateType"
        />
      </div>
    </div>
    <error-section :error="messageError" />
    <div
      class="send-sms__textarea-container"
    >
      <textarea
        id="countWords"
        ref="messageInputRef"
        v-model="innerMessage"
        placeholder="Type your message"
        :readonly="props.readOnly"
        class="send-sms__textarea"
        @input="emit('update:message', innerMessage)"
        @unput="handleMessageInput"
      />
      <span class="send-sms__counter text_xs color_tertiary">
        {{ counterString }}
      </span>
      <default-button
        v-if="tierFeatures.statuses?.ai_assistant_in_campaigns?.exist"
        color="transparent-gr"
        form="square"
        size="m"
        :title="!showInputFeature ? $t('actions.showAi') : $t('actions.hideAi')"
        icon-name="bx bxs-magic-wand"
        class="send-sms__show-generate-button"
        @action="handleShowAiPrompt"
      />
    </div>
    <step-menu
      ref="menu"
      :brand="brand"
      :video="video"
      :template="template"
      :message="innerMessage"
      type="send-sms"
      :is-ai-message-enabled="isAiMessageEnabled"
      :manual-task-settings="manualTaskSettings"
      :is-need-manual-approve="isNeedManualApprove"
      @update:is-ai-message-enabled="$emit('update:is-ai-message-enabled', $event)"
      @update:manual-task-settings="updateManualTaskSettings"
      @update:is-need-manual-approve="emit('update:is-need-manual-approve', $event)"
      @add-template-to-area="handlePlaceholderPasting"
    />
  </div>
</template>
<script setup>
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import GeneratePromptSteps from '@/components/base/GeneratePrompt/GeneratePromptSteps.vue';
  import ErrorSection from '@/components/updateWorkflow/performAction/editActions/ErrorSection.vue';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { computed, onMounted, ref, nextTick } from 'vue';
  import { useSnack } from '@/lib/useSnack';
  import { useI18n } from '@/i18n';
  import { formatPhoneNumber } from '@/utils/dialer/utils';
  import { useStore } from '@/store';
  import { useTextareaResize } from '@/use/useTextareaResize';
  import { getAvgTextLength, pastePlaceholderInText } from '@/use/campaigns/useCampaignStepPlaceholders';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { tierFeatures } from '@/data/tiersFeatures';

  const props = defineProps({
    brand: {
      type: String,
      default: '',
    },
    messageError: {
      type: Object,
      default: () => ({
        warningText: '',
        errorText: '',
      }),
    },
    message: {
      type: String,
      default: '',
    },
    actionId: {
      type: String,
      default: '',
    },
    subject: {
      type: String,
      default: '',
    },
    isSales: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      default: null,
    },
    video: {
      type: Object,
      default: null,
    },
    isAiMessageEnabled: {
      type: Boolean,
      default: false,
    },
    isNeedManualApprove: {
      type: Boolean,
      default: false,
    },
    manualTaskSettings: {
      type: Object,
      default: () => ({}),
    },
    phoneType: {
      type: String,
      default: 'work',
    },
    voxPhone: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits([
    'update:manual-task-settings',
    'update:is-need-manual-approve',
    'update:phone-type',
    'update:vox-phone',
    'update:message',
  ]);

  const store = useStore();
  const { $snack } = useSnack();
  const { t } = useI18n();

  const messageInputRef = ref(null);
  const innerMessage = ref(props.message || '');
  const avgMessageLength = computed(() => {
    return getAvgTextLength(innerMessage.value);
  });
  const maxMessageLength = 765;

  const handleMessageInput = () => {
    resizeTextarea();
    emit('update:message-length', avgMessageLength);
  };

  const counterString = computed(() => `${avgMessageLength.value}/${maxMessageLength}`);

  const { resizeTextarea } = useTextareaResize(messageInputRef);

  onMounted(() => {
    resizeTextarea();
    emit('update:message-length', avgMessageLength);
    emit('update:max-message-length', maxMessageLength);
  });

  const handleShowAiPrompt = () => {
    useUpgradeModal(() => {
      showInputFeature.value = !showInputFeature.value;
    }, 'ai_assistant_in_campaigns');
  };

  // #region Phone Type
  const phoneTypes = [
    {
      name: t('modals.sendSms.selects.type.personal'),
      _id: 'personal',
    },
    {
      name: t('modals.sendSms.selects.type.enriched'),
      _id: 'predicted',
    },
  ];

  const selectedPhoneType = ref(phoneTypes.find(e => e._id === props.phoneType));

  onMounted(() => {
    if (!selectedPhoneType.value) {
      selectedPhoneType.value = phoneTypes[0];
      emit('update:phone-type', selectedPhoneType.value._id);
    }
  });

  const updateType = (type) => {
    selectedPhoneType.value = type;
    emit('update:phone-type', type._id);
  };
  // #endregion

  const updateManualTaskSettings = (settings) => {
    emit('update:manual-task-settings', settings);
  };

  const generatedMessage = ref('');

  const showInputFeature = ref(false);

  // #region Phone Number
  const numbersList = computed(() => {
    return store.getters['vox/numbersList']?.filter(e => {
      return e.is_sms_enabled && e.status === 'active';
    })?.map(phone => {
      phone.name = formatPhoneNumber(phone.number);
      return phone;
    });
  });

  const selectedPhoneNumber = ref(numbersList.value.find(p => p.id === props.voxPhone));

  onMounted(() => {
    nextTick();
    if (!selectedPhoneNumber.value) {
      selectedPhoneNumber.value = numbersList.value[0];
      emit('update:vox-phone', selectedPhoneNumber.value.id);
    }
  });

  const updateVoxPhone = (phone) => {
    selectedPhoneNumber.value = phone;
    emit('update:vox-phone', phone.id);
  };
  // #endregion

  const onGenerateMessage = (value) => {
    generatedMessage.value = value;
    innerMessage.value = value;
    emit('update:message', value);
  };

  const handlePlaceholderPasting = (placeholder, space) => {
    if (avgMessageLength.value + space + 1 <= maxMessageLength) {
      innerMessage.value = pastePlaceholderInText(innerMessage.value, placeholder, messageInputRef.value);
    } else {
      $snack.error(
        t('notifications.messageCanNotBeLongerThan', {
          count: maxMessageLength,
        })
      );
    }
  };
</script>
<style lang="scss">
.send-sms {
  &__generate-prompt-step {
    transition: 0.2s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    max-height: 0;

    &_open {
      padding: 12px 16px;
      opacity: 1;
      visibility: visible;
      max-height: 100vh;
    }
  }

  & .base-item {
    padding: 0;
    border-radius: 0;
    border: none;
    min-height: 180px;
    max-height: 180px;
    border-bottom: 1px solid rgba(10, 32, 51, 0.06);
    box-shadow: none;
    background: var(--neutral-50);
  }

  &__header {
    padding: 8px 0px 0px;
    display: flex;
    flex-flow: column;
    &-subject, &-from, &-to {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 4px;
      border-bottom: 1px solid rgba(10, 32, 51, 0.06);
    }
  }

  &__input {
    padding: 4px;
  }

  &__thread {
    min-width: 180px;
    .uikit-tooltip__list {
      min-width: 250px;
      right: 0;
    }
  }

  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
    &_active {
      opacity: 1;
    }
  }

  &__textarea-container {
    max-height: 200px;
    overflow: auto;
    padding: 8px 16px;
    background: var(--neutral-50);
    border-top: 1px solid rgba(10, 32, 51, 0.06);
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(2, 1fr);
  }

  &__textarea {
    grid-row: 1/-1;
  }

  &__counter,
  &__show-generate-button {
    position: sticky;
    justify-self: end;
  }

  &__counter {
    top: 0;
    align-self: start;
  }

  &__show-generate-button {
    bottom: 0;
    align-self: end;
  }

  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    min-height: 160px;
    background: inherit;
  }
}
</style>
