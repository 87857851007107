<template>
  <section>
    <feature-upgrade-placeholder
      v-if="tierFeatures.statuses?.dash_tasks?.upgrade"
      icon="bx-task"
      feature-name="Manual Tasks"
      feature-id="dash_tasks"
      description="Open the possibility of creating and performing manual tasks"
    />

    <template v-else>
      <article
        v-if="!isLiApproveEnabled"
        class="approval-menu-card p-2 mb-2"
      >
        <header class="flex-row flex-justify-content-between">
          <h2 class="text_s text_medium">
            {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.approval.requireApproval.title') }}
          </h2>

          <default-switcher
            :is-checked="isNeedManualApprove"
            size="xs"
            @change="handleManualApproveToggle"
          />
        </header>

        <main>
          <p class="text_xs color_tertiary mt-05">
            {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.approval.requireApproval.info') }}
          </p>
        </main>
      </article>

      <manual-menu
        v-if="isNeedManualApprove"
        :manual-task-settings="manualTaskSettings"
        @update:manual-task-settings="emit('update:manual-task-settings', $event)"
      />
    </template>
  </section>
</template>

<script setup>
  import ManualMenu from '@/components/updateWorkflow/performAction/manualMenu/ManualMenu.vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import FeatureUpgradePlaceholder from '@/components/upgradeWalls/FeatureUpgradePlaceholder.vue';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { tierFeatures } from '@/data/tiersFeatures';

  const props = defineProps({
    isLiApproveEnabled: {
      type: Boolean,
      default: false,
    },
    isNeedManualApprove: {
      type: Boolean,
      default: false,
    },
    manualTaskSettings: {
      type: Object,
      default: () => ({}),
    },
  });

  const emit = defineEmits(['update:is-need-manual-approve', 'update:manual-task-settings']);

  const handleManualApproveToggle = () => {
    useUpgradeModal(() => {
      emit('update:is-need-manual-approve', !props.isNeedManualApprove);
      emit('update:manual-task-settings', {
        follow_up: null,
        assignee: null,
        priority: null,
      });
    }, 'dash_tasks');
  };
</script>

<style lang="scss">
.approval-menu-card {
  max-width: 320px;
  border-radius: 8px;
  border: 1px solid var(--neutral-300);
}
</style>
