import { ref } from 'vue';

const $snackbar = ref([]);

const snackbarLimit = 3;
const snackDuration = 3000;

export const useSnack = () => {
  const $snack = {
    /**
     * Create new Snack with custom options
     * @param {plugins.Snack.SnackOptions} options Options for Snack
    */
    create (options) {
      const snackId = 'snack-' + Date.now();

      $snackbar.value.push({
        _id: snackId,
        duration: snackDuration,
        ...options,
      });

      if ($snackbar.value.length > snackbarLimit) {
        $snackbar.value.shift();
      }
    },

    /**
     * Create new Default Snack
     * @param {plugins.Snack.SnackOptions['message']} message - Snack message
     * @param {plugins.Snack.SnackOptions} [options] - Snack options
    */
    show (message, options = {}) {
      const _options = {
        ...options,
        message,
      };

      this.create(_options);
    },

    /**
     * Create new Success Snack
     * @param {plugins.Snack.SnackOptions['message']} message - Snack message
     * @param {plugins.Snack.SnackOptions} [options] - Snack options
    */
    success (message, options = {}) {
      const _options = {
        ...options,
        type: 'success',
        message,
      };

      this.create(_options);
    },

    /**
     * Create new Warning Snack
     * @param {plugins.Snack.SnackOptions['message']} message - Snack message
     * @param {plugins.Snack.SnackOptions} [options] - Snack options
    */
    warning (message, options = {}) {
      const _options = {
        ...options,
        type: 'warning',
        message,
      };

      this.create(_options);
    },

    /**
     * Create new Error Snack
     * @param {plugins.Snack.SnackOptions['message']} message - Snack message
     * @param {plugins.Snack.SnackOptions} [options] - Snack options
    */
    error (message, options = {}) {
      const _options = {
        ...options,
        type: 'error',
        message,
      };

      this.create(_options);
    },

    /**
     * Remove Snack by `id`
     * @param {number} id - Snack id to remove
    */
    remove (id) {
      const snackIndex = $snackbar.value.findIndex(snack => snack._id === id);
      if (snackIndex !== -1) {
        $snackbar.value.splice(snackIndex, 1);
      }
    },
  };

  return {
    $snack,
    $snackbar,
  };
};
