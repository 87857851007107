<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div id="app">
    <div
      class="animation"
      :class="theme ? 'animation-enter-active' : 'animation-leave-active'"
    />
    <vue-extend-layouts v-if="loaded" />
    <default-notifications
      class="default-notifications"
    />
    <default-snackbar />
    <portal-target
      name="body-end"
      multiple
    />
    <portal-target
      name="body-end-2"
      multiple
    />
    <portal-target
      name="walls"
      multiple
    />
    <portal-target
      name="confirm"
      multiple
    />
  </div>
</template>

<script>
  import VueExtendLayouts from 'vue-extend-layout';
  import DefaultNotifications from '@/plugins/notify/DefaultNotifications';
  import DefaultSnackbar from '@/components/base/uiKit.v3/DefaultSnackbar.vue';
  import { checkToken, createToken } from '@/api/tokenMethods';
  import { getSpace } from '@/api/spaceMethods';
  import { getUser } from '@/api/userMethods';
  import { mapGetters } from 'vuex';
  import { baseSocketsMixin } from '@/mixins/baseSocketsMixin';
  import { fullPageLoader } from '@/mixins/fullPageLoader';
  import { firstFetchMixin } from '@/mixins/firstFetchMixin';
  import { changeColorMixin } from '@/mixins/changeColorMixin';
  import '@fontsource/inter/400.css';
  import '@fontsource/inter/500.css';
  import '@fontsource/inter/600.css';
  import { setPartnerStack } from './utils/externalScripts/setPartnerStack';

  export default {
    name: 'App',
    components: {
      VueExtendLayouts,
      DefaultNotifications,
      DefaultSnackbar,
    },
    mixins: [baseSocketsMixin, fullPageLoader, firstFetchMixin, changeColorMixin],
    data () {
      return {
        animation: false,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
      theme () {
        return this.$store.getters.theme;
      },
    },
    created () {
      // decoration for modals
      const app = this;
      function showWrapper (show) {
        return function () {
          show.apply(this, arguments);
          app.$store.dispatch('OPEN_MODAL', { isOpen: true, name: arguments[0] });
        };
      }
      function hideWrapper (hide) {
        return function () {
          hide.apply(this, arguments);
          app.$store.dispatch('OPEN_MODAL', {
            isOpen: false,
            name: arguments[0],
          });
        };
      }
      const show = showWrapper(this.$modal.show);
      const hide = hideWrapper(this.$modal.hide);
      this.$modal.show = show;
      this.$modal.hide = hide;

      if (process.env.VUE_APP_BRAND === 'buzz') {
        setPartnerStack();
      }
    },
    async beforeMount () {
      const query = new URLSearchParams(window.location.search);
      const token = query.get('token');
      this.setConfigTitle();
      if (token) {
        localStorage.setItem('token', token);
      }
      this.setColors();
      this.loaded = false;

      window.onpopstate = async () => {
        await this.checkEverything();
      };
      await this.checkEverything();
      window.addEventListener('storage', async (e) => {
        if (!this.space) {
          if (
            (e.key === 'token' || e.key === 'loggedIn') &&
            !document.hasFocus()
          ) {
            document.location.reload();
          }
        }
      });
    },
    mounted () {
      let localTheme = localStorage.getItem('theme');

      if (
        !['campaign/edit', 'campaign/add', '/smart-sequences'].some((path) =>
          window.location.pathname.includes(path)
        )
      ) {
        sessionStorage.removeItem('workflow');
      }

      // Setting the default dark theme based on brand
      if (process.env.VUE_APP_BRAND === 'cynapseanalitics' && !localTheme) {
        localTheme = 'dark';
      }

      this.$store.dispatch('SWITCH_THEME', localTheme);
    },

    methods: {
      getDefaultColors (config) {
        const query = new URLSearchParams(window.location.search);
        [
          'primaryCl',
          'lightTheme',
          'darkTheme',
          'logo',
          'dark_logo',
          'auth_logo',
          'dark_auth_logo',
          'loader_logo',
          'dark_loader_logo',
          'logoSize',
          'support',
          'appId',
        ].forEach((key) => {
          const queryParam = query.get(key);
          if (queryParam) {
            sessionStorage.setItem(key, queryParam);
          }
        });
        const primaryCl =
          sessionStorage.getItem('primaryCl') ||
          config?.color ||
          process.env.VUE_APP_COLOR ||
          'rgb(43, 133, 208)';
        const logoSize =
          sessionStorage.getItem('logoSize') ||
          process.env.VUE_APP_LOGO_SIZE ||
          40;
        let lightTheme = sessionStorage.getItem('lightTheme') ||
          config?.light_theme || {
            bg: process.env.VUE_APP_LIGHT_BG_CL || '#FFFFFF',
            body: process.env.VUE_APP_LIGHT_BODY_CL || '#F0F2F5',
          };
        if (typeof lightTheme === 'string') {
          lightTheme = JSON.parse(lightTheme);
        }
        let darkTheme =
          sessionStorage.getItem('darkTheme') ||
          (config?.dark_theme
            ? config.dark_theme
            : {
              bg: process.env.VUE_APP_DARK_BG_CL || '#152e4d',
              body: process.env.VUE_APP_DARK_BODY_CL || '#12263f',
            });
        if (typeof darkTheme === 'string') {
          darkTheme = JSON.parse(darkTheme);
        }
        const lightScss = `
          --background-color: ${lightTheme.bg};
          --primary-cl: ${primaryCl};
          --body-bg-cl: ${lightTheme.body};
          --ternary-bg-cl: ${lightTheme.body};
          --logo-size: ${logoSize}px;
          `;
        const darkScss = `
          --background-color: ${darkTheme.bg};
          --primary-cl: ${primaryCl};
          --body-bg-cl: ${darkTheme.body};
          --ternary-bg-cl: ${darkTheme.body};
          `;
        return `
          :root {
            ${lightScss}
          }
          [data-theme="dark"] {
            ${darkScss}
          }
          .dark-primary-bg_hover:hover{
             background: ${this.pSBC(-0.7, primaryCl)};
          }
          `;
      },
      setColors (config) {
        document.querySelector('#colorVars')?.remove();
        const style = document.createElement('style');
        style.setAttribute('id', 'colorVars');
        document.body.append(style);
        style.type = 'text/css';
        const css = this.getDefaultColors(config);
        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        return style;
      },

      setConfigTitle () {
        document.title = process.env.VUE_APP_TITLE || 'Sales Engagement Platform';
      },
      fetchAccount: async function () {
        const user = await getUser();
        this.$store.dispatch('user/SET_USER', user);
        if (user) {
          this.setColors(user.config);
        }

        // redirecting to auth if status is inactive

        if (user.status === 'inactive') {
          if (
            ![
              'privacy-policy',
              'terms-of-use',
              'sign-up',
              'third-party-login',
              'forgot-password',
              'restore-password',
              'accept-invite',
              'video',
            ].includes(this.$route.name)
          ) {
            localStorage.setItem('loggedIn', 'false');
            this.$route.name !== 'sign-in' &&
              (await this.$router.push({ path: '/auth/sign-in', query: this.$route.query }));
          }
        } else {
          // setting all data
          const account = await getSpace();
          await this.firstFetch(account, user);
        }
      },
      async checkToken () {
        try {
          return await checkToken();
        } catch (e) {
          if (e.code === 403) {
            window.location.search = '';
            return;
          }
          throw e;
        }
      },
      async checkEverything () {
        if (window.location.pathname !== '/error') {
          try {
            await this.checkToken();
            await this.fetchAccount();
          } catch (e) {
            if (e.message.includes('Token is not valid') || e.code === 401) {
              try {
                localStorage.setItem('loggedIn', 'false');
                localStorage.setItem('token', '');
                const params = {
                  utm_source: this.$route.query.utm_source,
                  utm_medium: this.$route.query.utm_medium,
                  utm_campaign: this.$route.query.utm_campaign,
                  utm_term: this.$route.query.utm_term,
                  utm_content: this.$route.query.utm_content,
                  ref: this.$route.query.ref,
                  invite: this.$route.query.invite,
                  i: this.$route.query.i,
                };
                await createToken(params);
                await this.fetchAccount();
              } catch (e) {
                this.$noty.error(e.message);
              }
            } else if (e.message === 'Failed to fetch') {
              window.location.pathname = '/error';
              this.loaded = true;
            } else {
              this.$noty.error(e.message);
            }
          } finally {
            this.loaded = true;
          }
        } else {
          this.loaded = true;
          try {
            await this.checkToken();
            window.location.href = '/' + window.location.search;
          } catch (e) {
            if (e.message !== 'Failed to fetch') {
              window.location.href = '/' + window.location.search;
            }
          }
        }
      },
    },
  };
</script>

<style lang="scss">
@import "boxicons/css/boxicons.min.css";
@import "@/styles/theme.scss";
@import "~vuejs-noty/dist/vuejs-noty.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }
}

body {
  width: 100vw;
  height: 100vh;
}

#app {
  @include parent-size;
  font-size: 16px;
  color: var(--text-color);
}

ul {
  list-style: none;
}

.animation {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 1;
  left: 0;
  top: 0;
}
.animation-enter-active {
  animation-delay: 0.5;
  animation: bounce-in 1s;
}
.animation-leave-active {
  animation-delay: 0.5;
  animation: bounce-out 1s;
}
@keyframes bounce-in {
  0% {
    z-index: 100;
    background: #12263e;
    clip-path: circle(0% at 100% 0%);
  }
  100% {
    clip-path: circle(200% at 100% 0%);
    z-index: -1;
  }
}
@keyframes bounce-out {
  0% {
    z-index: 100;
    background: #fff;
    clip-path: circle(0% at 0% 100%);
  }
  100% {
    clip-path: circle(200% at 100% 0%);
    z-index: -1;
  }
}

div {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
}

textarea {
  font-family: "Source Sanc Pro", sans-serif;
}

  .vm--modal {
    transition: 0.8s;
    background-color: inherit !important;
    border-radius: 8px !important;
    box-shadow: var(--shadow-xl) !important;
    overflow: initial !important;
  }
  @media (max-width: 768px) {
    .vm--modal {
      left: 0px !important;
      height: 100vh !important;
    }
  }
  /* Затемнение фона модалок */
  .vm--overlay {
    background: rgba(0, 16, 61, 0.48);
  }
  // скрывает блок чата (нижний правый угол),
  // который перекрывает конент, но не содержит в себе html
  #fc_frame {
    width: 0 !important;
    height: 0 !important;
  }

  .default-notifications {
    position: fixed;
    z-index: 99999;
    top: calc(#{$header-height} + 16px);
    right: 16px;
    width: 300px;
  }
</style>
