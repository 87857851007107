<template>
  <div class="flex-row flex-gap-8">
    <div class="flex-row flex-gap-8">
      <i :class="['bx icon_size_16 color_tertiary', contact.icon]" />
      <p class="text_xs text_weight_accent">
        {{ contact.name }}
      </p>
    </div>

    <div class="flex-row flex-gap-8 flex-grow-1 flex-justify-content-end overflow-hidden">
      <p
        v-if="contact.name === 'Phone' && contact.value"
        class="text_xs link text_nowrap text_ellipsis"
        @click.stop="addPhone(contact.value)"
      >
        {{ contact.value }}
      </p>
      <p
        v-else
        class="text_xs color_tertiary text_nowrap text_ellipsis"
      >
        {{ contact.value }}
      </p>
      <default-button
        form="square"
        size="s"
        icon-name="bx bx-duplicate"
        title-color="color_tertiary"
        hovered-title-color="hovered_primary"
        :has-paddings="false"
        @action="copyValue(contact.value)"
      />
      <i class="bx bxs-check-circle icon_size_16 color_success" />
    </div>
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import { eventBus } from '@/eventbus';
  import { eventBusEvents } from '@/utils/eventBusEvents';

  export default {
    name: 'ItemEnrichedBlock',
    components: {
      DefaultButton,
    },
    mixins: [differentFunctionsMixin],
    props: {
      contact: {
        type: Object,
        required: true,
      },
    },
    methods: {
      addPhone (value) {
        this.$emit('add-phone');
        eventBus.$emit(eventBusEvents.ADD_NUMBER, { number: value, profile: this.contact.profile });
      },
    },
  };
</script>
