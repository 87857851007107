<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="step-menu">
    <div class="step-menu__container">
      <div
        v-for="button in menuButtonsFiltered"
        :key="button._id"
        class="step-menu__item"
        :class="{ 'step-menu__item_active': chosenMenu === button._id }"
        @click="chooseMenu(button._id)"
      >
        <figure class="relative">
          <i
            class="step-menu__icon bx"
            :class="[
              button.icon,
              { 'step-menu__icon_active': chosenMenu === button._id },
              { 'step-menu__icon_active-ai': chosenMenu === 'AiMessageMenu' && chosenMenu === button._id },
            ]"
          />

          <span
            v-if="button.isBeta"
            class="step-menu__beta-icon color_white icon_size_10 flex-row-centered"
          >
            ß
          </span>
          <span
            v-if="hasStep(button)"
            class="step-menu__on-icon color_white icon_size_10 flex-row-centered"
          >
            <img
              src="@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/assets/icon-on.svg"
              alt="icon-on"
            >
          </span>
        </figure>

        <span
          class="step-menu__text step-menu__text_absolute color_tertiary text_xs "
          :class="[
            { 'color_primary': chosenMenu === button._id },
            {'color_purple': chosenMenu === 'AiMessageMenu' && chosenMenu === button._id }
          ]"
        >
          {{ button.name }}
        </span>
      </div>
    </div>
    <component
      :is="chosenMenu"
      :video="video"
      :template="template"
      :message="message"
      :subject="subject"
      :type="type"
      :disabled="!!template?._id"
      :is-ai-message-enabled="isAiMessageEnabled"
      :is-li-approve-enabled="isLiApproveEnabled"
      :manual-task-settings="manualTaskSettings"
      :is-need-manual-approve="isNeedManualApprove"
      @update:is-ai-message-enabled="$emit('update:is-ai-message-enabled', $event)"
      @update:manual-task-settings="$emit('update:manual-task-settings', $event)"
      @update:is-need-manual-approve="$emit('update:is-need-manual-approve', $event)"
      @add-template-to-area="addTemplateToArea"
      @add-practice="addPractice"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
      @unlink-template="$emit('unlink-template')"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
    />
  </div>
</template>
<script>
  import TemplatesMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplatesMenu';
  import VideoMenu from '@/components/updateWorkflow/performAction/menuFiles/VideoMenu';
  import PreviewMenu from '@/components/updateWorkflow/performAction/menuFiles/PreviewMenu';
  import PlaceholdersMenu from '@/components/updateWorkflow/performAction/menuFiles/PlaceholdersMenu';
  import PracticeMenu from '@/components/updateWorkflow/performAction/menuFiles/PracticeMenu';
  import ScoreMenu from '@/components/updateWorkflow/performAction/menuFiles/ScoreMenu';
  import StatisticMenu from '@/components/updateWorkflow/performAction/menuFiles/StatisticMenu';
  import CalendlyMenu from '@/components/updateWorkflow/performAction/menuFiles/CalendlyMenu';
  import LyneMenu from '@/components/updateWorkflow/performAction/menuFiles/LyneMenu';
  import AiMessageMenu from '@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/AiMessageMenu.vue';
  import ApprovalMenu from '@/components/updateWorkflow/performAction/menuFiles/ApprovalMenu';
  import { tierFeatures } from '@/data/tiersFeatures';
  import { mapGetters } from 'vuex';
  import { WorkflowActionsTypesEnum } from '@/data/workflowActions';
  export default {
    name: 'StepMenu',

    components: {
      TemplatesMenu,
      VideoMenu,
      PlaceholdersMenu,
      PreviewMenu,
      PracticeMenu,
      ScoreMenu,
      StatisticMenu,
      CalendlyMenu,
      LyneMenu,
      AiMessageMenu,
      ApprovalMenu,
    },
    props: {
      brand: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isTextIncludes: {
        type: Boolean,
        default: true,
      },
      video: {
        type: Object,
        default: null,
      },
      template: {
        type: Object,
        default: null,
      },
      subject: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      isAiMessageEnabled: {
        type: Boolean,
        default: false,
      },
      isNeedManualApprove: {
        type: Boolean,
        default: false,
      },
      manualTaskSettings: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        menuButtons: [
          {
            icon: 'bx-code-curly',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.name'),
            _id: 'PlaceholdersMenu',
          },
          {
            icon: 'bx-star',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.bestPractice.name'),
            _id: 'PracticeMenu',
          },
          {
            icon: 'bx-library',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.name'),
            _id: 'TemplatesMenu',
            hideOn: ['my-templates'],
          },
          {
            icon: 'bx-video',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.video.name'),
            _id: 'VideoMenu',
            hideOn: ['my-templates'],
            isVisible: tierFeatures.statuses?.video?.exist || false,
          },
          {
            icon: 'bxs-magic-wand color_purple step-menu__ai-icon',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.aiMessage.name'),
            _id: 'AiMessageMenu',
            hideOn: ['my-templates'],
            isBeta: true,
            isVisible: tierFeatures.statuses?.icebreakers?.exist || false,
          },
          {
            icon: 'bx-badge-check',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.approval.name'),
            _id: 'ApprovalMenu',
            hideOn: ['my-templates'],
            isBeta: true,
            isVisible: tierFeatures.statuses?.dash_tasks?.exist || false,
          },
          {
            icon: 'bx-file-find',
            name: this.$t('campaignsPage.editor.steps.modals.stepEdit.menu.preview.name'),
            _id: 'PreviewMenu',
          },
        ],
        placeholders: this.placeholdersToSubject,
        chosenMenu: 'PlaceholdersMenu',
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
      isLiApproveEnabled () {
        const excludedSteps = [WorkflowActionsTypesEnum.SEND_EMAIL];
        if (excludedSteps.includes(this.type)) {
          return false;
        }
        return this.account.is_li_approve;
      },
      menuButtonsFiltered () {
        const isTempatesPage = this.$route.name === 'my-templates';
        const allowedIds = [
          'PreviewMenu',
          'ApprovalMenu',
        ];

        return this.menuButtons.filter((btn) => {
          const hideOnRoute = btn.hideOn?.includes(this.$route.name);
          const isVisible = btn.isVisible !== false;

          if (!isTempatesPage && this.disabled) {
            return allowedIds.includes(btn._id);
          }

          return !hideOnRoute && isVisible;
        });
      },
    },
    watch: {
      disabled () {
        this.setChosenMenu();
      },
    },
    mounted () {
      this.setChosenMenu();
    },
    methods: {
      setChosenMenu () {
        if (this.disabled && this.$route.name !== 'my-templates') {
          this.chosenMenu = 'PreviewMenu';
        } else this.chosenMenu = 'PlaceholdersMenu';
      },
      chooseMenu (button) {
        this.chosenMenu = button;
      },
      addTemplateToArea (temp, space) {
        this.$emit('add-template-to-area', temp, space);
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.$emit('add-practice', item);
      },
      hasStep (button) {
        switch (true) {
        case this.video && button._id === 'VideoMenu':
        case this.template?._id && button._id === 'TemplatesMenu':
        case this.isAiMessageEnabled && button._id === 'AiMessageMenu':
        case this.isNeedManualApprove && button._id === 'ApprovalMenu':
          return true;
        default:
          return false;
        }
      },
    },
  };
</script>
<style lang="scss">
.step-menu {
  display: flex;
  flex-flow: column;
  padding: 16px;
  gap: 16px;

  &__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__item {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition: 0.1s;
    width: 80px;

    &:hover {
      opacity: 0.7;
    }

      &_active {
        cursor: initial;

        &:hover {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 32px;
      height: 32px;
      background: var(--neutral-50);
      border-radius: 50%;
      display: flex !important;
      align-items: center;
      justify-content: center;

      &_active {
        background: var(--primary-cl);
        color: white;
      }

      &_active-ai {
        background: var(--magic-button-bg-gradient) !important;
        color: white !important;
      }
    }

    &__beta-icon {
      position: absolute;
      right: -10%;
      bottom: -10%;
      line-height: 1;
      background-color: $cl-info;
      border-radius: 99px;
      width: 1.2em;
      height: 1.2em;
    }

    &__on-icon {
      position: absolute;
      right: 65%;
      bottom: -10%;
      line-height: 1;
      border-radius: 99px;
      width: 12px;
      height: 12px;
      z-index: 111;
    }

    &__ai-icon {
      color: #AB68FF;
      background: var(--magic-button-bg-subdued);
    }
  }
</style>
