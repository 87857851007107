import { ref } from 'vue';

const useContenteditable = () => {
  const templateInput = ref(false);
  const range = ref(null);
  const saveRange = () => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      range.value = window.getSelection().getRangeAt(0);
    }
  };
  /**
 * @param {import('vue').Ref<HTMLElement|import('vue').Component>} ref
 * @param {import('vue').Ref<String>} template
 */
  const addTemplate = (template, ref) => {
    templateInput.value = true;

    // ДАЛЬШЕ БОГА НЕТ
    const html = `<span>${template}</span>`;
    if (!range.value) {
      const newRange = new Range();
      newRange.setStart(ref?.$el || ref, 0);
      newRange.setEnd(ref?.$el || ref, 0);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(newRange);
      range.value = newRange;
    }
    // IE9 and non-IE
    range.value.deleteContents();
    const sel = window.getSelection();

    // Range.createContextualFragment() would be useful here but is
    // non-standard and not supported in all browsers (IE9, for one)
    const el = document.createElement('div');
    el.innerHTML = html;
    const frag = document.createDocumentFragment();
    let node;
    let lastNode;
    while ((node = el.firstChild)) {
      lastNode = frag.appendChild(node);
    }
    range.value.insertNode(frag);

    // Preserve the selection
    if (lastNode) {
      const newRange = range.value.cloneRange();
      newRange.setStartAfter(lastNode);
      newRange.collapse(true);
      sel.removeAllRanges();
      sel.addRange(newRange);
      range.value = newRange;
    }

    setTimeout(() => {
      templateInput.value = false;
    });
  };
  return { saveRange, addTemplate };
};

export { useContenteditable };
