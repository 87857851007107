<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="send-inmail-step">
    <generate-prompt-steps
      :message="innerMessage"
      :generate.sync="generateMessage"
      :action-id="actionId"
      class="send-inmail-step__generate-prompt-step"
      :class="{'send-inmail-step__generate-prompt-step_open' : showInputFeature }"
    />
    <div class="send-inmail-step__header">
      <span class="text_s color_secondary">
        {{ $tc("common.subject").concat(":") }}
      </span>
      <input
        ref="inmailSubject"
        v-model="inmailSubject"
        placeholder=""
        :readonly="readOnly"
        class="send-inmail-step__input ui-kit-input__item text_s"
        @focus="isSubject = true"
        @mouseenter="showTemplateMenu"
        @mouseleave="isShowTemplateMenu = false"
      >
      <span
        class="send-inmail-step__counter text_xs color_tertiary margin-left"
        :class="{'color_danger': avgSubjectLength > maxSubjectLength}"
      >
        {{ counterSubject }}
      </span>
    </div>
    <div
      class="send-inmail-step__textarea-container"
      @mouseenter="showTemplateMenu"
      @mouseleave="isShowTemplateMenu = false"
    >
      <textarea
        id="countWords"
        ref="messageInputRef"
        v-model="innerMessage"
        :placeholder="$t('placeholders.typeYourMessage')"
        :readonly="readOnly"
        class="send-inmail-step__textarea"
        @focus="isSubject = false"
        @input="handleMessageInput"
      />
      <span
        class="send-inmail-step__counter text_xs color_tertiary"
        :class="{'color_danger': avgMessageLength > maxMessageLength}"
      >
        {{ isSales ? counterString : '' }}
      </span>
      <default-button
        v-if="tierFeatures.statuses?.ai_assistant_in_campaigns?.exist"
        color="transparent-gr"
        form="square"
        size="m"
        :title="!showInputFeature ? $t('actions.showAi') : $t('actions.hideAi')"
        icon-name="bx bxs-magic-wand"
        class="send-inmail-step__show-generate-button"
        @action="handleShowAiPrompt"
      />
      <template-unlink-menu
        v-show="isShowTemplateMenu"
        class="send-inmail-step__template-menu"
        :class="{
          'send-inmail-step__template-menu_active': isShowTemplateMenu,
        }"
        :template="template"
        @unlink-template="unlinkTemplate"
        @edit-template="editTemplate"
      />
    </div>
    <error-section :error="subjectError" />
    <error-section :error="messageError" />
    <div class="send-inmail-step__credits">
      <default-switcher
        :is-checked="sales"
        size="s"
        @change="switchSales"
      />
      <div class="send-inmail-step__credits-desc">
        <span class="text_xs text_bold">
          {{
            $t("modals.sendInMail.switchers.allowUseMyCredits.label", {
              name: user.config.name,
            })
          }}
        </span>
        <span class="text_xs color_tertiary">
          {{
            $t("modals.sendInMail.switchers.allowUseMyCredits.description", {
              name: user.config.name,
            })
          }}
        </span>
      </div>
    </div>
    <step-menu
      :brand="brand"
      :video="video"
      :template="template"
      :message="innerMessage"
      :subject="inmailSubject"
      type="li_send_inmail"
      :is-ai-message-enabled="isAiMessageEnabled"
      :manual-task-settings="manualTaskSettings"
      :is-need-manual-approve="isNeedManualApprove"
      @update:is-ai-message-enabled="$emit('update:is-ai-message-enabled', $event)"
      @update:manual-task-settings="$emit('update:manual-task-settings', $event)"
      @update:is-need-manual-approve="$emit('update:is-need-manual-approve', $event)"
      @add-template-to-area="addTemplate"
      @add-practice="addPractice"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
      @unlink-template="unlinkTemplate"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
    />
  </div>
</template>
<script>
  import { ref } from 'vue';
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import { templatesMixin } from '@/mixins/templatesMixin.js';
  import ErrorSection from '@/components/updateWorkflow/performAction/editActions/ErrorSection.vue';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu.vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher';
  import GeneratePromptSteps from '@/components/base/GeneratePrompt/GeneratePromptSteps.vue';
  import { mapGetters } from 'vuex';
  import TemplateUnlinkMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplateUnlinkMenu';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { useTextareaResize } from '@/use/useTextareaResize';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { tierFeatures } from '@/data/tiersFeatures';

  export default {
    name: 'SendInmailStep',
    components: {
      DefaultSwitcher,
      GeneratePromptSteps,
      StepMenu,
      TemplateUnlinkMenu,
      DefaultButton,
      ErrorSection,
    },
    mixins: [templatesMixin, addTemplateMixin],
    props: {
      brand: {
        type: String,
        default: '',
      },
      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      subjectError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      message: {
        type: String,
        default: '',
      },

      subject: {
        type: String,
        default: '',
      },
      isSales: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      template: {
        type: Object,
        default: null,
      },
      actionId: {
        type: String,
        default: '',
      },
      video: {
        type: Object,
        default: null,
      },
      isAiMessageEnabled: {
        type: Boolean,
        default: false,
      },
      isNeedManualApprove: {
        type: Boolean,
        default: false,
      },
      manualTaskSettings: {
        type: Object,
        default: () => ({}),
      },
    },
    setup () {
      const messageInputRef = ref(null);

      const { resizeTextarea, resetTextareaHeight } = useTextareaResize(messageInputRef);

      return {
        messageInputRef,
        resizeTextarea,
        resetTextareaHeight,
      };
    },
    data () {
      return {
        innerMessage: this.message,
        inmailSubject: this.subject,
        isSubject: false,
        isShowTemplateMenu: false,
        sales: this.isSales,
        generateMessage: '',
        showInputFeature: false,
        maxSubjectLength: 200,
        maxMessageLength: 1900,
        tierFeatures,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
        selectedCredential: 'credentials/selectedCredential',
      }),
      counterString () {
        return `${this.avgMessageLength}/${this.maxMessageLength}`;
      },
      counterSubject () {
        return `${this.avgSubjectLength}/${this.maxSubjectLength}`;
      },
      avgMessageLength () {
        return this.getAvgTextLength(this.innerMessage) +
          // зарезервированное место под попись  в конце сообщения
          this.selectedCredential.full_name.length + 2 + 1;
      },
      avgSubjectLength () {
        return this.getAvgTextLength(this.inmailSubject);
      },
    },
    watch: {
      innerMessage () {
        this.$emit('update:message', this.innerMessage);
        if (this.isSales) {
          this.$emit('update:message-length', this.avgMessageLength);
        }
      },
      isSales (sales) {
        if (!sales) {
          this.$emit('update:message-length', 0);
        } else { this.$emit('update:message-length', this.avgMessageLength); }
      },
      generateMessage (value) {
        this.innerMessage = value;
      },
      inmailSubject () {
        this.$emit('update:subject', this.inmailSubject);
        this.$emit('update:subject-length', this.avgSubjectLength);
      },
      template () {
        if (this.template) {
          this.innerMessage = this.template?.text_template || '';
          this.inmailSubject = this.template?.subject || '';
        }
        this.resetTextareaHeight();
      },
      message () {
        this.innerMessage = this.message;
      },
    },
    created () {
      this.$emit('update:subject-length', this.avgSubjectLength);
      this.$emit('update:max-subject-length', this.maxSubjectLength);
      this.$emit('update:message-length', this.avgMessageLength);
      this.$emit('update:max-message-length', this.maxMessageLength);
    },
    mounted () {
      this.resizeTextarea();
    },
    methods: {
      handleShowAiPrompt () {
        useUpgradeModal(() => {
          this.showInputFeature = !this.showInputFeature;
        }, 'ai_assistant_in_campaigns');
      },
      handleMessageInput () {
        this.resizeTextarea();
      },
      notifyAboutSubjectLengthLimit () {
        this.$noty.error(
          this.$t('notifications.subjectCanNotBeLongerThan', {
            count: this.maxSubjectLength,
          })
        );
      },
      notifyAboutMessageLengthLimit () {
        this.$noty.error(
          this.$t('notifications.messageCanNotBeLongerThan', {
            count: this.maxMessageLength,
          })
        );
      },
      addThread (thread) {
        this.chosenThread = thread;
        this.emailSubject = thread.name;
      },
      switchSales () {
        this.sales = !this.sales;
        this.$emit('update:isSales', this.sales);
      },
      unlinkTemplate () {
        this.isShowTemplateMenu = false;
        this.$emit('unlink-template');
      },
      editTemplate (template) {
        this.isShowTemplateMenu = false;
        this.$emit('edit-template', template);
      },
      showTemplateMenu () {
        if (this.readOnly) {
          this.isShowTemplateMenu = true;
        }
      },
      addTemplate (template, space) {
        if (this.isSubject) {
          if (this.avgSubjectLength + space + 1 <= this.maxSubjectLength) {
            this.inmailSubject = this.addTemplateToArea(
              this.inmailSubject,
              template,
              'inmailSubject'
            );
          } else {
            this.notifyAboutSubjectLengthLimit();
          }
        } else {
          if (this.avgMessageLength + space + 1 <= this.maxMessageLength) {
            this.innerMessage = this.addTemplateToArea(this.innerMessage, template, 'messageInputRef');
          } else {
            this.notifyAboutMessageLengthLimit();
          }
        }
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.inmailSubject = item.subject;
        this.innerMessage = item.message;
      },
    },
    sockets: {
      generated_data (response) {
        const res = JSON.parse(response);
        this.innerMessage = res.payload.message;
      },
    },
  };
</script>
<style lang="scss">
.send-inmail-step {
  &__header {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
  }

  &__generate-prompt-step {
    transition: 0.2s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    max-height: 0;

    &_open {
      padding: 12px 16px;

      opacity: 1;
      visibility: visible;
      max-height: 100vh;
    }
  }

  &__input {
    min-height: 24px;
  }

  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
    &_active {
      opacity: 1;
    }
  }

  &__credits {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;

    &-desc {
      display: flex;
      flex-flow: column;
    }
  }

  &__textarea-container {
    max-height: 200px;
    overflow: auto;
    padding: 8px 16px;
    background: var(--neutral-50);
    border-top: 1px solid rgba(10, 32, 51, 0.06);
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(2, 1fr);
  }

  &__textarea {
    grid-row: 1/-1;
  }

  &__counter,
  &__show-generate-button {
    position: sticky;
    justify-self: end;
  }

  &__counter {
    top: 0;
    align-self: start;
  }

  &__show-generate-button {
    bottom: 0;
    align-self: end;
  }

  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    min-height: 160px;
    background: inherit;
  }
}
</style>
