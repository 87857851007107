<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="make-call">
    <div
      class="make-call__textarea-container"
    >
      <div
        class="make-call__header"
      >
        <default-switcher
          size="s"
          :is-checked="isPhoneAvailable"
          @change="isPhoneAvailable = !isPhoneAvailable"
        /> <span class="text_s">Create task if phone is available </span>
      </div>
      <div class="make-call__error">
        <span
          v-for="(error, key) in props.messageError"
          :key="`${key}Message`"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
        <span
          v-for="(error, key) in props.subjectError"
          :key="`${key}Subject`"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
      </div>
      <textarea
        id="countWords"
        ref="messageArea"
        v-model="note"
        placeholder="Call note"
        :readonly="props.readOnly"
        class="make-call__textarea"
        @change="updateManualTaskSettings({ note })"
      />
    </div>

    <manual-menu
      class="p-2"
      :manual-task-settings="manualTaskSettings"
      @update:manual-task-settings="updateManualTaskSettings"
    />
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import ManualMenu from '@/components/updateWorkflow/performAction/manualMenu/ManualMenu.vue';

  const props = defineProps({
    brand: {
      type: String,
      default: '',
    },
    messageError: {
      type: Object,
      default: () => ({
        warningText: '',
        errorText: '',
      }),
    },
    subjectError: {
      type: Object,
      default: () => ({
        warningText: '',
        errorText: '',
      }),
    },
    message: {
      type: String,
      default: '',
    },

    subject: {
      type: String,
      default: '',
    },
    isSales: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      default: null,
    },
    video: {
      type: Object,
      default: null,
    },
    manualTaskSettings: {
      type: Object,
      default: () => ({}),
    },
  });

  const emit = defineEmits(['update:manual-task-settings']);

  const isPhoneAvailable = ref(true);
  const note = ref(props.manualTaskSettings.note || '');

  const updateManualTaskSettings = (settings) => {
    emit('update:manual-task-settings', settings);
  };
</script>
<style lang="scss">
.make-call {
  &__header {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
  }

  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    padding: 8px 16px;
    min-height: 160px;
    border-top: 1px solid rgba(10, 32, 51, 0.06);
    border-bottom: 1px solid rgba(10, 32, 51, 0.06);
    background: var(--neutral-50);
  }

  &__input {
    min-height: 24px;
  }
}
</style>
