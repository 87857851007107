<template>
  <div
    v-if="supplementaryInfo.coordinates"
    class="map-basic"
  >
    <div class="map-basic__image-wrapper">
      <img
        :src="location.mapSrc"
        class="map-basic__image map-basic__image_bordered"
      >
      <div class="map-basic__image-overlay" />
    </div>

    <div
      class="map-basic__info flex flex-justify-content-between"
    >
      <default-avatar
        size="m"
        class="cursor-pointer"
        :photo-link="profile.photo_link"
        :status-color="profileStatusColor"
        @click.native="createNewTab(profile.link)"
      />
      <div>
        <div class="flex-row flex-gap-4 text_xs color_tertiary">
          <p>{{ location.address }}</p>
          <p
            v-if="time.value"
          >
            {{ time.value }}
          </p>
        </div>
        <div
          v-if="temperature"
          class="flex-row flex-gap-4 flex-justify-content-end text_xs color_tertiary"
        >
          <p>{{ temperature.description }}</p>
          <img
            v-if="temperature.icon"
            :src="temperature.icon"
            class="image image_16"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import moment from 'moment';
  import { getMapAndWeather } from '@/api/profilesMethods';
  import { mapGetters } from 'vuex';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar.vue';
  import variables from '@/styles/_variables.module.scss';

  export default {
    name: 'MapBasic',
    components: { DefaultAvatar },
    mixins: [differentFunctionsMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        supplementaryInfo: {
          timezone: {},
          weather: {},
          coordinates: {},
        },
        time: {
          handler: null,
          value: '',
        },
        temperature: {
          description: '',
          icon: '',
        },
        location: {
          mapSrc: '',
          address: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      profileStatusColor () {
        return variables[`status-${this.profile.tag}`] || variables[`status-${this.profile.status}`];
      },
    },
    watch: {
      'profile._id' () {
        this.init();
      },
    },
    created () {
      this.init();
    },
    beforeDestroy () {
      clearInterval(this.time.handler);
    },
    methods: {
      async init () {
        this.supplementaryInfo = await this.getInfo();
        this.setInfo();
      },
      async getInfo () {
        try {
          return await getMapAndWeather(this.profile._id, this.selectedCredential._id);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      setInfo () {
        if (this.supplementaryInfo.timezone) {
          this.setTime();
        }
        if (this.supplementaryInfo.weather) {
          this.setTemperature();
        }
        if (this.supplementaryInfo.coordinates) {
          this.setLocation();
        }
      },
      setTime () {
        clearInterval(this.time.handler);
        const { delta } = this.supplementaryInfo.timezone;
        const offset = delta / 60;
        this.time.handler = setInterval(() => {
          this.time.value = moment().utcOffset(offset).startOf('minutes').format('(h:mm A)');
        }, 100);
      },
      setTemperature () {
        this.temperature.description = this.supplementaryInfo.weather.name + ', ' + Math.round(this.supplementaryInfo.weather.temp * 9 / 5 + 32) + '°F';
        this.temperature.icon = this.supplementaryInfo.weather.icon_url;
      },
      setLocation () {
        const zoom = 10;
        const size = 500;
        const mapURL = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static';
        const { longitude, latitude } = this.supplementaryInfo.coordinates;
        const token = this.supplementaryInfo.api_key || process.env.VUE_APP_MAPBOX_API_KEY;
        this.location.mapSrc = `${mapURL}/${longitude},${latitude},${zoom}/${size}x${size / 2}?access_token=${token}`;
        this.location.address = this.supplementaryInfo.coordinates.address;
      },
    },
    sockets: {
      map_and_weather (response) {
        response = JSON.parse(response);
        if (this.profile._id !== response.profile_id) {
          return;
        }
        delete response.profile_id;
        this.supplementaryInfo = response;
        this.setInfo();
      },
    },
  };
</script>
