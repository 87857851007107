<template>
  <section class="manual-menu-info">
    <article
      v-for="article in ARTICLES"
      :key="article._id"
      class="manual-menu-info__article background_neutral_50 p-1"
    >
      <header class="flex-row flex-gap-6">
        <i
          class="bx icon_size_16 color_tertiary"
          :class="article.icon"
        />

        <h2 class="text_s text_medium">
          {{ article.title }}
        </h2>
      </header>

      <main class="manual-menu-info__main">
        <p class="text_xs color_tertiary mt-05">
          {{ article.text }}
        </p>
      </main>
    </article>
  </section>
</template>

<script setup>
  import { useI18n } from '@/i18n';

  const { t } = useI18n();

  const ARTICLES = [
    {
      _id: 'time_period',
      icon: 'bx-calendar',
      title: t('campaignsPage.editor.steps.modals.stepEdit.manualMenu.timePeriod.title'),
      text: t('campaignsPage.editor.steps.modals.stepEdit.manualMenu.timePeriod.description'),
    },
    {
      _id: 'assignee',
      icon: 'bx-user-circle',
      title: t('campaignsPage.editor.steps.modals.stepEdit.manualMenu.assignee.title'),
      text: t('campaignsPage.editor.steps.modals.stepEdit.manualMenu.assignee.description'),
    },
    {
      _id: 'priority',
      icon: 'bx-flag',
      title: t('campaignsPage.editor.steps.modals.stepEdit.manualMenu.priority.title'),
      text: t('campaignsPage.editor.steps.modals.stepEdit.manualMenu.priority.description'),
    },
  ];
</script>

<style lang="scss">
.manual-menu-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(72px, max-content);
  column-gap: 8px;

  &__article {
    max-height: 144px;
    display: grid;
    grid-template-rows: min-content 1fr;
    border-radius: 8px;
  }

  &__main {
    overflow: auto;
  }
}
</style>
