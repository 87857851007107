<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <section
    ref="vldParent"
    class="videos-constructor vld-parent"
    :class="{ 'videos-constructor_upgrade': isUpgrade }"
  >
    <feature-upgrade-placeholder
      v-if="isUpgrade"
      class="parent-size"
      icon="bx-video"
      feature-name="Buzz Video"
      feature-id="video"
      description="Use the ability to record individual videos for each prospect"
    />

    <template v-else>
      <constructor-switcher
        class="videos-constructor__switcher"
        :is-edit-mode="isEditMode"
        @change="isEditMode = !isEditMode"
      />
      <constructor-main-buttons
        class="videos-constructor__buttons"
        :has-update="hasUpdate"
        @action="changeScenario"
      />
      <div class="videos-constructor__body">
        <!-- Если кэшировать View, то при изменении templates они не буду меняться -->
        <keep-alive include="ConstructorEdit">
          <component
            :is="'constructor-' + (isEditMode ? 'edit' : 'preview')"
            :scenario="updatedScenario"
            class="videos-constructor__body-content"
            @close-modal="$emit('close-modal')"
            @update="updateHandler"
          />
        </keep-alive>
      </div>
    </template>
  </section>
</template>

<script>
  import ConstructorSwitcher from '@/components/videoEditor/constructor/ConstructorSwitcher';
  import ConstructorMainButtons from '@/components/videoEditor/constructor/ConstructorMainButtons';
  import ConstructorEdit from '@/components/videoEditor/constructor/ConstructorEdit';
  import ConstructorPreview from '@/components/videoEditor/constructor/ConstructorPreview';
  import FeatureUpgradePlaceholder from '@/components/upgradeWalls/FeatureUpgradePlaceholder.vue';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { eventBus } from '@/eventbus';
  import { cloneDeep, isEqual } from 'lodash';
  import { eventBusEvents } from '@/utils/eventBusEvents';
  import { tierFeatures } from '@/data/tiersFeatures';

  export default {
    name: 'VideosConstructor',
    components: {
      ConstructorSwitcher,
      ConstructorMainButtons,
      ConstructorEdit,
      ConstructorPreview,
      FeatureUpgradePlaceholder,
    },
    mixins: [loaderMixin],
    data () {
      return {
        tierFeatures,
        key: false,
        isEditMode: true,
        updatedScenario: {
          name: '',
          video_settings: {
            description_template: '',
            title_template: '',
            bubble_size: 'medium',
            scroll_speed: 'normal',
            calendar_link: '',
            call_to_action_title: '',
            mirror_video: true,
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        scenarioList: 'scenario/scenarioList',
      }),
      scenario () {
        return this.scenarioList.find(s => s._id === this.$route.params.videoId);
      },
      hasUpdate () {
        if (this.scenario?._id && !this.scenario.video_src) {
          return true;
        }
        return !this.scenario || !isEqual(this.updatedScenario, this.scenario);
      },
      isUpgrade () {
        return tierFeatures.statuses?.video?.upgrade || false;
      },
    },
    async created () {
      try {
        this.loaded = false;
        await this.init();
      } catch (e) {
        this.$noty.error(e.message);
      } finally {
        this.loaded = true;
      }
    },
    methods: {
      async init () {
        if (!this.$route.params.videoId) {
          return;
        }
        if (this.scenarioList.length === 0) {
          await this.$store.dispatch('scenario/SET_SCENARIO_LIST');
        }
        this.updatedScenario = cloneDeep(this.scenario);
      },
      updateHandler (value, key, isSettings) {
        if (isSettings) {
          this.updatedScenario.video_settings = { ...this.updatedScenario.video_settings, [key]: value };
        } else {
          this.updatedScenario = { ...this.updatedScenario, [key]: value };
        }
      },
      changeScenario () {
        eventBus.$emit(eventBusEvents.CHANGE_SCENARIO);
      },
    },
  };
</script>

<style lang="scss">
.videos-constructor {
  position: relative;
  @include parent-size;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &_upgrade {
    grid-template-rows: 100%;
  }

  &__body {
    width: 100%;
    padding: 16px;
    overflow-y: scroll;

    &-content {
      max-width: 1024px;
      margin: 0 auto;
    }
  };

  &__switcher {
    width: 280px;
    height: 36px;
    margin: 16px auto 8px;
  }

  &__buttons {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>
