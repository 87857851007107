<template>
  <div class="parent-size">
    <feature-upgrade-placeholder
      v-if="messagesGroup === 'messages' && tierFeatures.statuses?.social_automation?.upgrade"
      class="parent-size"
      icon="bx-message-square-dots"
      feature-name="Social Automation"
      feature-id="social_automation"
      description="Open the possibility of Social Automation"
    />
    <feature-upgrade-placeholder
      v-else-if="messagesGroup === 'sms' && tierFeatures.statuses?.sms?.upgrade"
      class="parent-size"
      icon="bx-message-square-dots"
      feature-name="SMS"
      feature-id="sms"
      description="Open the possibility to send SMS directly from our interface"
    />
    <ul
      v-else-if="messages.length || approve"
      class="flex-column flex-gap-16 pb-1"
    >
      <card-messages
        v-for="message in messages"
        :key="message._id"
        :class="[
          'list-messages__item',
          { 'flex-align-self-end flex-align-items-end': message.is_me },
        ]"
        :message="message"
        @edit-approval="$emit('edit-approval', $event)"
        @delete-message="$emit('delete-message', $event)"
      />

      <approve-card-message
        v-if="isApproveCardMessageVisible"
        :approve="approve"
        :is-menu-disabled="isApproveMenuDisabled"
        @edit-approve-message="emit('edit-message', approve.approved_text)"
        @update:approve="emit('update:approve')"
      />
    </ul>
    <ul
      v-else-if="loading"
      class="flex-column flex-gap-16"
    />
    <div
      v-else-if="!messages.length"
      class="flex-column-centered parent-size flex-gap-16 color_tertiary"
    >
      <i class="bx bx-envelope icon_size_60" />
      <p class="text_s text_weight_accent">
        {{ $t('inboxPage.chatSection.notifications.noMessages') }}
      </p>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import CardMessages from '@/components/inboxPage/chat/messages/CardMessages';
  import ApproveCardMessage from '@/components/inboxPage/chat/messages/ApproveCardMessage.vue';
  import FeatureUpgradePlaceholder from '@/components/upgradeWalls/FeatureUpgradePlaceholder.vue';
  import { tierFeatures } from '@/data/tiersFeatures';
  import { WorkflowActionsTypesEnum } from '@/data/workflowActions';

  const props = defineProps({
    approve: {
      type: [Object, null],
      default: null,
    },
    isApproveMenuDisabled: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    messagesGroup: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits('update:approve');

  const isApproveCardMessageVisible = computed(() => {
    const approveStatus = props.approve?.dash_task_status;
    const taskType = props.approve?.dash_task_type;
    const approveText = props.approve?.approved_text || '';

    const allowedStatuses = ['new', 'hold'];
    const allowedTypes = [
      WorkflowActionsTypesEnum.LI_CONNECT_BY_EMAIL,
      WorkflowActionsTypesEnum.LI_CONNECT_PROFILE,
      WorkflowActionsTypesEnum.LI_SEND_MESSAGE,
    ];

    return allowedStatuses.includes(approveStatus) &&
      allowedTypes.includes(taskType) &&
      approveText.trim().length;
  });
</script>
