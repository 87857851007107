/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import { TEMPLATES } from '@/utils/workflows/templates';

const placeholderRegExp = /\{.*?\}/gm;
const placeholderValueRegExp = /[^{}]+(?=\})/gm;
const defaultPlaceholderReservedSpace = 10;

const allDefaultPlaceholders = computed(() => {
  return TEMPLATES.value.reduce((prev, section) => {
    return [...prev, ...section.data];
  }, []);
});

/**
 * @param {string} placeholderValue
 * @returns {number}
 */
const getDefaultPlaceholderReservedSpace = (placeholderValue) => {
  const defaultPlaceholder = allDefaultPlaceholders.value.find((template) => {
    return template.value === placeholderValue;
  });
  return defaultPlaceholder?.space || defaultPlaceholderReservedSpace;
};

/**
 * @param {string} text
 * @returns {string}
 */
const getTextWithoutPlaceholders = (text) => {
  return text.replaceAll(placeholderRegExp, '');
};

/**
 * @param {string} text
 * @returns {number}
 */
const getPlaceholdersReservedSpaceSum = (text) => {
  const placeholders = text.match(placeholderValueRegExp);
  return placeholders?.reduce((acc, placeholder) => {
    return acc + getDefaultPlaceholderReservedSpace(placeholder);
  }, 0) || 0;
};

/**
 * @param {string} text
 * @returns {number}
 */
export const getAvgTextLength = (text) => {
  return getTextWithoutPlaceholders(text).length + getPlaceholdersReservedSpaceSum(text);
};

/**
 * @param {string} text
 * @param {string} placeholder
 * @param {HTMLInputElement|HTMLTextAreaElement} element
 */
export const pastePlaceholderInText = (text, placeholder, element) => {
  let cursorStartPos = element.selectionStart;
  const cursorEndPos = element.selectionEnd;

  setTimeout(() => {
    cursorStartPos += placeholder.length + 1;
    element.selectionStart = element.selectionEnd = cursorStartPos;
  }, 10);

  let cursorMessage = text.substring(0, cursorStartPos);

  if (!cursorMessage.endsWith(' ')) {
    cursorMessage += ' ';
  }

  return cursorMessage + placeholder + text.substring(cursorEndPos, text.length);
};
