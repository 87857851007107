<template>
  <header class="flex-row-between">
    <div class="flex-row flex-gap-8">
      <default-avatar
        size="s"
        :photo-link="profile.photo_link"
        :status-color="profileStatusColor"
      />
      <div class="flex-column flex-gap-4">
        <p class="text_s text_weight_accent">
          {{ profile.full_name }}
        </p>
        <custom-switchers-list
          class="flex flex-gap-4"
          button-type="circle"
          :button-style="{
            padding: '4px 12px',
          }"
          :list="switcherList"
          :selected="activeMessagesGroup"
          @select="$emit('update:activeMessagesGroup', $event)"
        />
      </div>
    </div>
    <div class="flex flex-gap-8 margin-left flex-align-items-center">
      <router-link
        v-if="$route.name !== 'inbox'"
        :to="{ name: 'inbox', params: { profileId: profileIdFromInbox } }"
      >
        <default-button
          v-if="profileIdFromInbox"
          class="inbox-page__button"
          :title="$t('inboxPage.chatSection.header.buttons.openWithInbox')"
          form="ellipse"
          color="primary"
          size="s"
        />
      </router-link>
      <default-tooltip
        data-closable="profileActions"
        :items="profileActions"
        list-position="right"
        :has-triangle="false"
        @choose="profileActionsHandler"
      >
        <template #default>
          <default-button
            icon-name="bx-dots-vertical-rounded"
            color="tertiary"
            form="square"
            size="m"
          />
        </template>
      </default-tooltip>
    </div>
    <input-modal
      name="forwardModal"
      :title="$t('inboxPage.chatSection.header.buttons.forwardTo')"
      :primary-button-text="$t('inboxPage.chatSection.header.buttons.forward')"
      :show.sync="modals.forward"
      :tags.sync="recentlyUsedEmails"
      :placeholder="$tc('common.email')"
      height="auto"
      @send="sendForward"
    />
    <delete-confirmation-modal
      :show.sync="modals.removeWorkflow"
      width="500px"
      name="RemoveFromCampaign"
      title="Remove prospect from Campaign"
      text="Are you sure you want to remove this prospect from campaign?"
      :button-text="t('actions.remove')"
      @confirm="changeWorkflow(null)"
    />
    <default-modal
      :show.sync="modals.changeWorkflow"
      name="ChangeProfileWorkflowInbox"
      width="60%"
      height="80%"
    >
      <change-profile-workflow
        class="modal"
        :selected-amount="1"
        :excluded-workflows="[profile.workflow]"
        @close="modals.changeWorkflow = false"
        @change-workflow="changeWorkflow"
      />
    </default-modal>
    <push-crm-modal
      :show="modals.pushToCrm"
      name="inboxModalPushToCrmModal"
      :integration-name="pushToCrmOptions.id"
      :is-missing="pushToCrmOptions.isMissing"
      :number-push-to-crm="1"
      :progress-load="0"
      @update:show="modals.pushToCrm = $event"
    />
    <push-to-crm-owner-modal
      :show.sync="modals.chooseCrmOwner"
      name="inboxModalPushToCrmOwnerModal"
      :crm="pushToCrmOptions.id"
      @update:show="modals.chooseCrmOwner = $event"
      @push-to-crm="pushToHubspot"
    />
  </header>
</template>

<script setup>
// HeaderInbox
  import DefaultModal from '@/components/base/DefaultModal.vue';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import CustomSwitchersList from '@/components/base/CustomSwitchersList';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import InputModal from '@/modals/inputModal';
  import ChangeProfileWorkflow from '@/components/profilesPage/ChangeProfileWorkflow.vue';
  import PushCrmModal from '@/modals/PushCRMModal.vue';
  import PushToCrmOwnerModal from '@/modals/PushToCrmOwnerModal.vue';
  import variables from '@/styles/_variables.module.scss';
  import { useRecentEmails } from '@/mixins/usedEmailsVue3';
  import { useConfig } from '@/mixins/useConfig';
  import { forwardToEmail, pushToCrm } from '@/api/profilesMethods';
  import { useI18n } from '@/i18n';
  import { computed, reactive, ref } from 'vue';
  import { useStore } from '@/store';
  import { useSnack } from '@/lib/useSnack';
  import { tierFeatures } from '@/data/tiersFeatures';
  import { emailRegex } from '@/data/regexes';

  class ActionData {
    /**
     * @param {'tag'|'forward'|'blacklist'|'workflow'} type
     * @param {string} name
     * @param {string} value
     * @param {string} iconName
     */
    constructor (type, name, value, iconName) {
      this.name = name;
      this.type = type;
      this.value = value;
      this.iconName = iconName;
    }
  }
  const props = defineProps({
    activeMessagesGroup: {
      type: String,
      required: true,
    },
    hasOldMessagesButton: {
      type: Boolean,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    profileIdFromInbox: {
      type: String,
      default: '',
    },
  });
  const emit = defineEmits(['change-workflow', 'update-profile-prop', 'update:hasOldMessagesButton', 'blacklist']);

  const { t, tc } = useI18n();
  const profileActionsData = [
    // // new ActionData(
    //   'tag',
    //   t('inboxPage.chatSection.header.buttons.markAsLead'),
    //   'interested',
    //   'bx-label'
    // ),
    // // new ActionData(
    //   'tag',
    //   t('inboxPage.chatSection.header.buttons.markAsCustomer'),
    //   'lead',
    //   'bx-user'
    // ),
    new ActionData(
      'tag',
      t('inboxPage.chatSection.header.buttons.notInterested'),
      'not_interested',
      'bx-block'
    ),
    new ActionData(
      'forward',
      t('inboxPage.chatSection.header.buttons.forwardToEmail'),
      'forward',
      'bx-envelope'
    ),
    new ActionData(
      'crm',
      t('inboxPage.chatSection.header.buttons.pushToHubspot'),
      'hubspot',
      'bx-cloud-download'
    ),
    new ActionData(
      'blacklist',
      t('actions.removeFromBlacklist'),
      'ignored',
      'bx-minus'
    ),
    new ActionData(
      'blacklist',
      t('actions.excludeFromCampaigns'),
      'blacklisted',
      'bx-plus'
    ),
    new ActionData(
      'workflow',
      t('actions.changeCampaign'),
      'change',
      'bx-briefcase'
    ),
    new ActionData(
      'workflow',
      t('actions.removeFromCampaign'),
      'remove',
      'bx-trash'
    ),

  ];

  const store = useStore();
  const { $snack } = useSnack();

  const switcherList = ref([
    {
      value: 'messages',
      name: t('servicesNames.linkedIn'),
      isVisible: tierFeatures.statuses?.social_automation?.exist || false,
    },
    {
      value: 'emails',
      name: tc('common.email', 2),
      isVisible: tierFeatures.statuses?.email_automation?.exist || false,
    },
    {
      value: 'sms',
      name: t('common.sms'),
      isVisible: tierFeatures.statuses?.sms?.exist || false,
    },
    {
      value: 'tasks',
      name: tc('common.task', 2),
      isVisible: tierFeatures.statuses?.dash_tasks?.exist || false,
    },
    {
      value: 'activity',
      name: tc('common.activity'),
    },
  ]);
  const modals = reactive({
    forward: false,
    changeWorkflow: false,
    removeWorkflow: false,
    pushToCrm: false,
    chooseCrmOwner: false,
  });
  const { recentlyUsedEmails } = useRecentEmails();
  const { $config } = useConfig();
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
  const account = computed(() => store.getters['account/account']);
  const profileStatusColor = computed(() => variables[`status-${props.profile.tag}`] || variables[`status-${props.profile.status}`]);
  const profileActions = computed(() => {
    const actions = profileActionsData
      .filter(data => {
        switch (data.type) {
        case 'tag':
          if (data.value === 'not_interested') {
            return ['interested', 'lead'].includes(props.profile.tag);
          }
          return props.profile.tag !== data.value;
        case 'forward':
          return $config.brand !== 'yourleadtool';
        case 'blacklist':
          if (!['blacklisted', 'ignored'].includes(props.profile.blacklist_status)) {
            return data.value === 'blacklisted';
          }
          if (props.profile.blacklist_status === 'ignored') {
            return data.value === 'blacklisted';
          }
          if (props.profile.blacklist_status === 'blacklisted') {
            return data.value === 'ignored';
          }
        }
        return true;
      });
    if (props.hasOldMessagesButton && props.activeMessagesGroup === 'messages') {
      actions.unshift(
        new ActionData(
          'messages',
          t('inboxPage.chatSection.header.buttons.showOldMessages'),
          'old_messages',
          'bx-message'
        )
      );
    }
    return actions;
  });
  /** @param {ActionData} item */
  const profileActionsHandler = (item) => {
    switch (item.type) {
    case 'tag':
      emit('update-profile-prop', { tag: item.value });
      break;
    case 'forward':
      modals.forward = true;
      break;
    case 'crm':
      modals.chooseCrmOwner = true;
      pushToCrmOptions.id = item.value;
      pushToCrmOptions.isMissing = !checkIfCrmTokenExists(item.value);
      break;
    case 'messages':
      emit('update:hasOldMessagesButton', false);
      break;
    case 'blacklist':
      emit('update-blacklist', item.value);
      break;
    case 'workflow':
      showChangeWorkflow(item);
    }
  };
  const showChangeWorkflow = ({ value }) => {
    switch (value) {
    case 'change':
      modals.changeWorkflow = true;
      break;
    case 'remove':
      modals.removeWorkflow = true;
    }
  };
  const changeWorkflow = (newWorkflow, startOnChange) => {
    emit('change-workflow', newWorkflow, startOnChange);
  };
  const sendForward = (email) => {
    try {
      if (!emailRegex.test(email)) {
        $snack.error(t('notifications.emailIncorrect'));
        return;
      }
      if (!recentlyUsedEmails.value.includes(email)) {
        recentlyUsedEmails.value.push(email);
      }
      forwardToEmail(props.profile._id, selectedCredential.value._id, email);
      $snack.success(t('inboxPage.chatSection.header.notifications.forwardedToEmail'));
    } catch (e) {
      $snack.error(e.message);
    }
  };

  const pushToCrmOptions = reactive({ id: null, isMissing: true });

  const pushToHubspot = async ({ owner, isDeduplicate, emails }) => {
    modals.chooseCrmOwner = false;
    await pushToCrm(
      'hubspot',
      owner?._id || '',
      [props.profileIdFromInbox],
      selectedCredential.value._id,
      isDeduplicate,
      emails
    );
    modals.pushToCrm = true;
  };

  function checkIfCrmTokenExists (crmId) {
    return Object.keys(account.value.crm_tokens).includes(crmId);
  }
</script>
