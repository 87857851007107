import { useSnack } from '@/lib/useSnack';

const { $snack } = useSnack();
function getFeaturesByCategory (objSource, category) {
  if (!objSource) {
    return {};
  }
  const filtered = Object.entries(objSource).filter(([_, value]) => value.category === category);
  if (!filtered.length) {
    $snack.error(`Could not find features for category ${category}`);
  }

  return Object.fromEntries(filtered);
}
export { getFeaturesByCategory };
