import { computed } from 'vue';
import i18n from '@/i18n';

/** @type {import('vue').ComputedRef<Record<number, string>>} */
export const PRIORITY = computed(() => ({
  500: i18n.t('priority.urgent'),
  400: i18n.t('priority.high'),
  300: i18n.t('priority.medium'),
  200: i18n.t('priority.low'),
  100: i18n.t('priority.noPriority'),
}));

/** @type {Record<string, number>} */
export const PRIORITY_ENUM = Object.freeze({
  URGENT: 500,
  HIGH: 400,
  MEDIUM: 300,
  LOW: 200,
  NO_PRIORITY: 100,
});

/** @type {Record<string, string>} */
export const PRIORITY_COLORS = Object.freeze({
  500: 'color_danger',
  400: 'color_warning',
  300: 'color_info',
  200: 'color_tertiary',
  100: 'color_black',
});
