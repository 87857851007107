<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="default-input-with-button">
    <div
      class="default-input-with-button__wrapper"
      :class="{ 'default-input-with-button__wrapper_focused': isInputFocused }"
      @keyup.enter="emit('get-option', $event)"
    >
      <default-input
        :value="textInput"
        input-id="companyName"
        :name="$t('placeholders.typeYourPrompt')"
        :placeholder="placeholder ? placeholder : $t('placeholders.typeYourPrompt')"
        :is-disabled="isLoading"
        :is-nude="true"
        size="s"
        autocomplete="off"
        :should-trim-on-change="true"
        :max-characters="1024"
        @update:value="emit('update:text-input', $event)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      >
        <template #afterInput>
          <div class="default-input-with-button__buttons-wrapper">
            <default-buttons-select
              v-if="message"
              :buttons-group-data="buttonsGroupData"
              :is-loading="isLoading"
              :is-disabled="!textInput"
              title-color="color_white"
              background-color="background_ai-button"
              hovered-background-color="background_hovered_ai-button"
              disabled-background-color="background_ai-button opacity_100"
              noty-message-on-disabled="Type message goal, please"
              size="s"
              @action="emit('get-option', $event)"
              @select-option="emit('select-option', $event)"
            />

            <default-button
              v-else
              size="s"
              title-color="color_white"
              background-color="background_ai-button"
              hovered-background-color="background_hovered_ai-button"
              disabled-background-color="background_ai-button opacity_100"
              icon-name="bxs-magic-wand"
              :is-loading="isLoading"
              :is-disabled="!textInput"
              noty-message-on-disabled="Type message goal, please"
              :title="
                $t(
                  'campaignsPage.editor.steps.modals.stepEdit.buttons.generatePrompt'
                )
              "
              loading-title="Generating..."
              form="square"
              @action="emit('action')"
            />
          </div>
        </template>
      </default-input>
    </div>
  </div>
</template>

<script setup>
  // DefaultInputWithButton
  import { ref } from 'vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultButtonsSelect from '@/components/base/uiKit.v3/DefaultButtonsSelect.vue';

  defineProps({
    message: {
      type: String,
      default: '',
    },
    generate: {
      type: String,
      default: '',
    },
    buttonsGroupData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits([
    'update:text-input',
    'action',
    'get-option',
    'select-option',
    'focus',
    'blur',
  ]);

  const isInputFocused = ref(false);

  const onInputFocus = () => {
    isInputFocused.value = true;
    emit('focus');
  };

  const onInputBlur = () => {
    isInputFocused.value = false;
    emit('blur');
  };
</script>

<style lang="scss">
  .default-input-with-button {
    &__wrapper {
      position: relative;
      border-radius: 9px;
      border: 1px solid transparent;
      background: linear-gradient(white, white) padding-box, linear-gradient(0deg, rgba(123, 97, 255, 1), rgba(176, 97, 255, 1)) border-box;
      box-shadow: 0px 2px 8px -6px rgba(176, 97, 255, 0.80), 0px -2px 8px -6px rgba(123, 97, 255, 0.80);
      transition: box-shadow .2s ease-out;

      &_focused {
        box-shadow: 0px 2px 8px -4px rgba(176, 97, 255, 0.80), 0px -2px 8px -4px rgba(123, 97, 255, 0.80);
      }
    }

    &__buttons-wrapper {
      flex-shrink: 0;
    }
  }
</style>
