<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <transition
    name="ai-prompt"
    appear
  >
    <div class="generate-prompt-inbox-wrapper">
      <div
        ref="resizeElement"
        class="generate-prompt-inbox"
      >
        <default-input-with-button
          :text-input="inputPrompt"
          :buttons-group-data="buttonsGroupData"
          :message="message"
          :is-loading="isLoading"
          :placeholder="$t('placeholders.typeYourPromptInbox')"
          @get-option="getSwitchPrompt"
          @select-option="setActiveMenuOptions"
          @action="generateText"
          @update:text-input="inputPrompt = $event"
        />
        <div class="mt-1 generate-prompt-inbox__options">
          <default-button
            v-for="option in updateOptions.inner"
            :key="option.name"
            size="s"
            :is-disabled="isLoading"
            :title="option.name"
            color="transparent-gr"
            form="rectangle"
            @action="chooseOption(option.description)"
          />
          <default-tooltip
            v-if="countItem != activeMenuOptions.length"
            data-closable="profileActions"
            :items="updateOptions.tooltip"
            list-position="right"
            :is-disabled="isLoading"
            :has-triangle="false"
            :is-fulled="true"
            @choose="chooseOption($event.description)"
          >
            <template #default>
              <default-button
                icon-name="bx-dots-vertical-rounded"
                color="transparent-gr"
                form="ellipse"
                size="s"
              />
            </template>
          </default-tooltip>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
  // GeneratePromptInbox
  import { ref, onBeforeUnmount, computed, onMounted, watch } from 'vue';
  import DefaultInputWithButton from '@/components/base/uiKit.v3/DefaultInputWithButton.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip.vue';

  import { useI18n } from '@/i18n';
  import { useSnack } from '@/lib/useSnack';
  import { generatePromptInbox } from '@/api/AIMethods';
  import { useSocketIo } from '@/use/socketIo';
  import { useResize } from '@/use/useResize';

  const props = defineProps({
    message: {
      type: String,
      default: '',
    },
    profileId: {
      type: String,
      default: '',
    },
    credentialsId: {
      type: String,
      default: '',
    },
    messageType: {
      type: String,
      default: '',
    },
    requestIdSuffix: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:generate']);

  const { sockets, setResponseTimeoutError, resetTimeout } = useSocketIo();
  const { $snack } = useSnack();
  const { t } = useI18n();

  const inputPrompt = ref('');
  const isLoading = ref(false);

  const recreateOptions = [
    {
      name: '💪 ' + t('aiPrompt.options.agressive.name'),
      description: t('aiPrompt.options.agressive.goal'),
    },
    {
      name: '🤙 ' + t('aiPrompt.options.laidBack.name'),
      description: t('aiPrompt.options.laidBack.goal'),
    },
    {
      name: '🤝 ' + t('aiPrompt.options.objectionHandle.name'),
      description: t('aiPrompt.options.objectionHandle.goal'),
    },
    {
      name: '🗓️ ' + t('aiPrompt.options.bookMeeting.name'),
      description: t('aiPrompt.options.bookMeeting.goal'),
    },
    {
      name: '🤑 ' + t('aiPrompt.options.salesy.name'),
      description: t('aiPrompt.options.salesy.goal'),
    },
  ];

  const refineOptions = [
    {
      name: '🤏 ' + t('aiPrompt.options.shorten.name'),
      description: t('aiPrompt.options.shorten.goal'),
    },
    {
      name: '☝️ ' + t('aiPrompt.options.elaborate.name'),
      description: t('aiPrompt.options.elaborate.goal'),
    },
    {
      name: '👨‍💼 ' + t('aiPrompt.options.professionalTone.name'),
      description: t('aiPrompt.options.professionalTone.goal'),
    },
    {
      name: '🤝 ' + t('aiPrompt.options.convincingTone.name'),
      description: t('aiPrompt.options.convincingTone.goal'),
    },
    {
      name: '👾 ' + t('aiPrompt.options.addJoke.name'),
      description: t('aiPrompt.options.addJoke.goal'),
    },
  ];

  const activeMenuOptions = ref(recreateOptions);

  watch(() => props.message, (newVal) => {
    if (!newVal) {
      activeMenuOptions.value = recreateOptions;
    } else {
      activeMenuOptions.value = refineOptions;
    }
  });

  const setActiveMenuOptions = (value) => {
    if (value === 'refine') {
      activeMenuOptions.value = refineOptions;
    } else if (value === 'recreate') {
      activeMenuOptions.value = recreateOptions;
    }
  };

  const countItem = ref(activeMenuOptions.value.length);

  const resizeElement = ref(null);

  useResize(resizeElement, ({ width }) => {
    setCountItem(width);
  });

  function setCountItem (width) {
    switch (true) {
    case width < 400:
      countItem.value = 2;
      return;
    case width < 500:
      countItem.value = 3;
      return;
    case width < 540:
      countItem.value = 4;
      return;
    default:
      countItem.value = 5;
    }
  }

  const updateOptions = computed(() => {
    return { inner: activeMenuOptions.value.slice(0, countItem.value), tooltip: activeMenuOptions.value.slice(countItem.value) };
  });

  const buttonsGroupData = computed(() => [
    {
      value: 'refine',
      name: t('campaignsPage.editor.steps.modals.stepEdit.buttons.refinePrompt'),
      loadingName: t('campaignsPage.editor.steps.modals.stepEdit.buttons.refiningPrompt'),
      icon: {
        name: 'bxs-magic-wand',
        color: 'color_tertiary',
      },
    },
    {
      value: 'recreate',
      name: t('campaignsPage.editor.steps.modals.stepEdit.buttons.recreatePrompt'),
      loadingName: t('campaignsPage.editor.steps.modals.stepEdit.buttons.recreatingPrompt'),
      icon: {
        name: 'bx-revision',
        color: 'color_tertiary',
      },
    },
  ]);

  const handleGeneratePrompt = async (
    userPrompt,
    profileId,
    credId,
    messageType,
    requestIdSuffix,
    message = ''
  ) => {
    if (!userPrompt) return;
    isLoading.value = true;
    try {
      await generatePromptInbox(userPrompt, profileId, credId, messageType, requestIdSuffix, message);
      handleResponseTimeoutError();
    } catch (e) {
      $snack.error(e.message);
    }
  };

  const chooseOption = async (text) => {
    inputPrompt.value = text;
    await generateText();
  };

  const generateText = async () => {
    await handleGeneratePrompt(
      inputPrompt.value,
      props.profileId,
      props.credentialsId,
      props.messageType,
      props.requestIdSuffix
    );
  };

  const refinePrompt = async () => {
    await handleGeneratePrompt(
      inputPrompt.value,
      props.profileId,
      props.credentialsId,
      props.messageType,
      props.requestIdSuffix,
      props.message
    );
  };

  const getSwitchPrompt = async (type) => {
    switch (type?.value) {
    case 'refine':
      await refinePrompt();
      return;
    default:
      await generateText();
    }
  };

  const generatedResponse = ref(null);

  const handleResponseTimeoutError = () => {
    setResponseTimeoutError(
      () => {
        isLoading.value = false;
      }
    );
  };

  onMounted(() => {
    sockets.subscribe('generated_data', (response) => {
      const res = JSON.parse(response);
      if (res?.request_id !== (props.profileId + props.requestIdSuffix)) return;
      resetTimeout();
      isLoading.value = false;
      inputPrompt.value = '';
      generatedResponse.value = res.payload?.message;
      emit('update:generate', res.payload?.message);
    });
  });

  onBeforeUnmount(() => {
    sockets.unsubscribe('generated_data');
  });

</script>
<style lang="scss">
$duration: .2s;
$halfDuration: calc($duration / 2);
$function: ease-out;

.generate-prompt-inbox {
  min-height: 0px;
  min-width: 0px;

  &-wrapper {
    display: grid;
    grid-template-rows: 1fr;
  }

  &__options {
    margin-top: 4px;
    display: flex;
    gap: 12px;
  }
}

.ai-prompt-enter-active {
  transition:
    opacity $duration $halfDuration $function,
    grid-template-rows $duration $function,
    margin $duration $function;
}

.ai-prompt-leave-active {
  transition:
    opacity $duration $function,
    grid-template-rows $duration $halfDuration $function,
    margin $duration $halfDuration $function;
};

.ai-prompt-enter,
.ai-prompt-leave-to {
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  grid-template-rows: 0fr;
};
</style>
