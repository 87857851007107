<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    v-if="!isLabelsShort"
    class="labels"
  >
    <p
      v-for="(label, i) in labels"
      :key="i"
      class="label"
      :style="{
        background: label.color + '1A',
        color: label.color,
      }"
      @click="$emit('click-label', label)"
    >
      {{ label.name }}
    </p>
  </div>
  <div
    v-else
    class="labels"
  >
    <p
      v-for="(label, i) in labelsList"
      :key="i"
      class="label flex-row"
      :style="{
        background: label.color + '1A',
        color: label.color,
      }"
      @click="$emit('click-label', label)"
    >
      <i
        v-if="label.iconName"
        class="bx icon_size_12 mr-05"
        :class="label.iconName"
      />

      <span
        class="text_ellipsis"
        :title="label.name"
      >
        {{ label.name }}
      </span>
    </p>
    <span
      v-if="labels.length - maxShownLabels > 0"
      class="label__text"
    >
      +{{ labels.length - maxShownLabels }}</span>
  </div>
</template>
<script>
  import { AiLabelsNames } from '@/data/ai/labels';

  export default {
    name: 'ProfileLabels',
    props: {
      labels: {
        type: Array,
        default: () => [],
      },
      isLabelsShort: {
        type: Boolean,
        default: false,
      },
      maxShownLabels: {
        type: Number,
        default: 3,
      },
    },
    computed: {
      // Remove archived and starred labels
      // profileLabels () {
      //   return this.labels.filter(label => label.type !== 'global');
      // },
      labelsList () {
        return this.labels.slice(0, this.maxShownLabels).map((label) => {
          switch (label.type) {
          case 'ai_generated':
            return {
              ...label,
              name: AiLabelsNames.value[label.name],
              iconName: 'bxs-magic-wand',
            };
          default:
            return label;
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.labels {
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  gap: 4px;
  white-space: nowrap;
}
.label {
  color: #fff;
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  min-width: 0px;
  max-width: max-content;

  &__text {
    color: var(--secondary-text-color);
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
