<template>
  <article
    :class="[
      'default-card',
      {
        'default-card_hovered default-card_hovered_bordered': isHovered,
        'default-card_activated': isActive,
      }
    ]"
  >
    <div class="flex-column-centered flex-gap-8">
      <default-avatar
        size="xs"
        :photo-link="profile.photo_link"
        :status-color="profileStatusColor"
      />
      <i
        :class="[
          'bx icon_size_16',
          isStarredProfile ? 'bxs-star color_yellow' : 'bx-star color_tertiary',
        ]"
        @click.stop="markProfile('Starred')"
      />
    </div>
    <div class="overflow-hidden flex-grow-1">
      <div class="flex-row flex-gap-4">
        <p class="text_xs text_weight_accent text_ellipsis">
          {{ profile.full_name || 'Unknown' }}
        </p>
        <default-label
          v-if="profile.wait_reasons.is_stuck"
          size="xs"
          label-name="Pending"
          custom-type="warning"
        />
      </div>
      <p class="card-dialogs__message mb-1">
        <span class="color_primary">
          {{ lastMessageTemplate.author + ': ' }}
        </span>
        {{ lastMessageTemplate.message }}
      </p>
      <labels-list
        v-if="profile.labels.length"
        :labels="profile.labels"
      />
    </div>
    <div class="flex-column-centered flex-justify-content-between">
      <p class="text_xs color_tertiary">
        {{ profile.last_message?.created_at ? getFromNowUTC(profile.last_message?.created_at) : '' }}
      </p>
      <div
        v-if="profile.unread_count"
        :class="{ 'card-dialogs__message-status': profile.unread_count }"
      />
      <default-tooltip
        data-closable="cardDialog"
        class="flex-align-self-end"
        :items="tooltipData"
        list-position="right"
        :has-triangle="false"
        @choose="tooltipHandler"
      >
        <template #default>
          <default-button
            icon-name="bx-dots-vertical-rounded"
            title-color="color_secondary"
            hovered-title-color="hovered_primary"
            form="square"
            size="s"
            :has-padding="false"
          />
        </template>
      </default-tooltip>
    </div>
  </article>
</template>
<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import LabelsList from '@/components/base/LabelsList';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  import { mapGetters } from 'vuex';
  import { cloneDeep } from 'lodash';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';
  import variables from '@/styles/_variables.module.scss';

  import {
    markUnread,
    markRead,
    syncDialog,
    addProfileLabel,
    deleteProfileLabel
  } from '@/api/profilesMethods';

  const specialLabels = [
    {
      color: '#F2C94C',
      name: 'Starred',
      type: 'global',
      _id: '627909429e09157c6b9c200c',
    },
    {
      color: '#4F4F4F',
      name: 'Archived',
      type: 'global',
      _id: '627909509e09157c6b9c200d',
    },
  ];

  export default {
    name: 'CardDialogs',
    components: {
      DefaultAvatar,
      DefaultLabel,
      DefaultTooltip,
      LabelsList,
      DefaultButton,
    },
    mixins: [fromNowFilterMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
      isHovered: {
        type: Boolean,
        default: false,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        lastMessageTemplate: {
          author: '',
          message: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      profileStatusColor () {
        return variables[`status-${this.profile.tag}`] || variables[`status-${this.profile.status}`];
      },
      isStarredProfile () {
        return this.profile.labels.some(label => label.type === 'global' && label.name === 'Starred');
      },
      isArchivedProfile () {
        return this.profile.labels.some(label => label.type === 'global' && label.name === 'Archived');
      },
      tooltipData () {
        return [
          {
            name: this.profile.unread_count
              ? this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.markAsRead')
              : this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.markAsUnread'),
            value: 'status',
            iconName: 'bx-envelope',
          },
          {
            name: this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.refreshDialog'),
            value: 'refresh',
            iconName: 'bx-refresh',
          },
          {
            name: this.isStarredProfile
              ? this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.removeFromStarred')
              : this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.addToStarred'),
            value: 'star',
            iconName: this.isStarredProfile ? 'bxs-star color_yellow' : 'bx-star',
          },
          {
            name: this.isArchivedProfile
              ? this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.removeFromArchived')
              : this.$t('inboxPage.chatsSidebar.chatCard.tooltipButtons.addToArchived'),
            value: 'archive',
            iconName: this.isArchivedProfile ? 'bxs-box color_primary' : 'bx-box',
          },
        ];
      },
    },
    created () {
      this.setLastMessageTemplate();
    },
    methods: {
      setLastMessageTemplate () {
        // let message;
        if (!this.profile.last_message) {
          return;
        }
        // if (this.profile.last_message.platform !== 'linkedin') {
        //   const split = this.profile.last_message.body;
        //   message = split.startsWith('<')
        //     ? this.profile.last_message.body
        //     : this.profile.last_message.text;
        //   const email = new DOMParser().parseFromString(message, 'text/html').querySelector('body');
        //   message = email.innerText;
        //   if (message.startsWith('<', 1) || message.includes('<!--')) {
        //     message = this.profile.last_message.text;
        //   }
        // } else {
        //   message = this.profile.last_message.text;
        // }
        this.lastMessageTemplate.message = this.profile.last_message_in_dialog;
        this.lastMessageTemplate.author = this.profile.last_message.is_me
          ? this.$t('common.you')
          : this.profile.first_name;
      },
      tooltipHandler (item) {
        switch (item.value) {
        case 'status':
          this.profile.unread_count ? this.markRead() : this.markUnread();
          break;
        case 'refresh':
          this.refreshDialog();
          break;
        case 'star':
          this.markProfile('Starred');
          break;
        case 'archive':
          this.markProfile('Archived');
          break;
        }
      },
      async markUnread () {
        try {
          const result = await markUnread(this.profile._id, this.selectedCredential._id);
          if (result) {
            const updatedProfile = cloneDeep(this.profile);
            updatedProfile.unread_count = 1;
            this.$emit('update-profile', updatedProfile);
            this.$noty.success(this.$t('inboxPage.chatsSidebar.chatCard.notifications.markedAsUnread'));
          } else {
            this.$noty.show(this.$t('inboxPage.chatsSidebar.chatCard.notifications.noMessagesYet'));
          }
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async markRead () {
        try {
          await markRead(this.profile._id, this.selectedCredential._id);
          const updatedProfile = cloneDeep(this.profile);
          updatedProfile.unread_count = 0;
          this.$emit('update-profile', updatedProfile);
          this.$noty.success(this.$t('inboxPage.chatsSidebar.chatCard.notifications.markedAsRead'));
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async refreshDialog () {
        try {
          await syncDialog(this.profile._id, this.selectedCredential._id);
          this.$noty.success(
            this.$t('inboxPage.chatsSidebar.chatCard.notifications.requestForRefreshingDialog', {
              fullName: this.profile.first_name.concat(' ', this.profile.last_name),
            })
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async markProfile (labelName) { // only for archive and starred labels
        const updatedLabel = specialLabels.find(l => l.name === labelName);
        const hasLabel = this['is' + labelName + 'Profile'];
        let updatedProfile = {};
        updatedProfile = hasLabel
          ? await this.deleteProfileLabelHandler(updatedLabel)
          : await this.addProfileLabelHandler(updatedLabel);
        this.$noty.success(this.$t('inboxPage.chatsSidebar.chatCard.notifications.chatMarksUpdated'));
        if (labelName === 'Archived') {
          return this.$emit('remove-profile', this.profile);
        }
        this.$emit('update-profile', updatedProfile);
      },
      async deleteProfileLabelHandler (updatedLabel) {
        try {
          await deleteProfileLabel(this.profile._id, updatedLabel._id, this.selectedCredential._id);
          const updatedProfile = cloneDeep(this.profile);
          updatedProfile.labels = updatedProfile.labels.filter(l => l._id !== updatedLabel._id);
          return updatedProfile;
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async addProfileLabelHandler (updatedLabel) {
        try {
          await addProfileLabel(this.profile._id, updatedLabel._id, this.selectedCredential._id);
          const updatedProfile = cloneDeep(this.profile);
          updatedProfile.labels = updatedProfile.labels.concat(updatedLabel);
          return updatedProfile;
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
<style lang="scss">
.card-dialogs {
  &__message {
    @include text_xs;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $t-light-tertiary;
    // ораничение по строкам
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    line-clamp: 2;
    // ораничение по строкам
  }

  &__message-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary-cl);
  }
}
</style>
