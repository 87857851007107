<template>
  <div
    class="workspace-labels"
  >
    <div class="flex-row flex-gap-4">
      <default-button
        v-if="!['all', 'auto'].includes(selectedFilter)"
        form="square"
        size="m"
        :title="$t('actions.addLabel')"
        class="workspace-labels__create-label"
        color="primary"
        icon-name="bx-plus"
        @action="show.create = true"
      />
      <default-button
        v-for="button in filterButtons"
        :key="button.id"
        form="ellipse"
        size="s"
        :title="button.name"
        :color="button.id === selectedFilter ? 'primary' : 'transparent'"
        @action="changeFilter(button.id)"
      />
    </div>

    <ul
      v-if="!hideList"
      ref="vldParent"
      class="vld-parent workspace-labels__list"
    >
      <preference-label-item
        v-for="label in labelsList"
        :key="label._id"
        :label-name="label.name"
        :label-type="label.type"
        :label-color="label.color"
        :update-label="updateLabel"
        :has-update="label.type !== 'auto'"
        :label-title-type="normalizeLabelNameByType(label.type)"
        @choose="chooseLabel"
      />
    </ul>

    <update-label-modal
      :show.sync="show.create"
      @update-label="createLabelHandler"
    />
    <delete-confirmation-modal
      text="Confirm deleting label"
      button-text="Delete label"
      :show.sync="show.delete"
      name="deleteLabel"
      @confirm="deleteLabelHandler"
    />
  </div>
</template>

<script setup>
  // WorkspaceLabels
  import { ref, computed, onBeforeMount } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';
  import PreferenceLabelItem from '@/components/preferencesPage/labels/PreferenceLabelItem.vue';
  import UpdateLabelModal from '@/modals/UpdateLabelModal.vue';
  import { createLabel, deleteLabel, getLabels } from '@/api/labelsMethods';
  import { loadingWrapper, useLoader } from '@/use/loader';
  import { useSnack } from '@/lib/useSnack';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n';

  const { vldParent, loaderWatcher } = useLoader();
  const { t, tc } = useI18n();
  const store = useStore();
  const { $snack } = useSnack();

  const isAdmin = computed(() => store.getters['account/isAdmin']);
  const space = computed(() => store.getters['account/account']);

  const labelId = ref('');
  const allLabels = ref([]);
  const selectedFilter = ref('all');
  const loading = ref(false);
  const isAiLabeling = ref(space.value.is_ai_labeling);

  const show = ref({
    create: false,
    delete: false,
  });

  const filterButtons = [
    {
      name: t('defaultFilters.filters.all'),
      id: 'all',
    },
    {
      name: tc('common.inbox'),
      id: 'profile',
    },
    {
      name: tc('common.template', 2),
      id: 'template',
    },
  ];

  const updateLabel = [
    {
      name: t('actions.delete'),
      id: 'delete',
      iconName: 'bx-trash',
    },
  ];

  const hideList = computed(() => {
    return !labelsList.value.length || (selectedFilter.value === 'auto' && isAiLabeling.value && space.value.is_ai_labeling === null);
  });
  const labelsList = computed(() => {
    if (selectedFilter.value === 'all') return allLabels.value;

    return allLabels.value.filter(
      (label) => label.type === selectedFilter.value
    );
  });

  loaderWatcher(loading);
  onBeforeMount(() => init());

  const _init = async () => {
    if (isAdmin.value) {
      filterButtons.push(
        {
          name: t('workspaceSettings.settingsList.labels.filters.adminCustomers'),
          id: 'space',
        }
      );
    }
    isAiLabeling.value = space.value.is_ai_labeling;

    await getLabelsHandler(['profile']);
    await getLabelsHandler(['template']);
    await getLabelsHandler(['space']);
  };
  const init = loadingWrapper(_init, loading);

  const normalizeLabelNameByType = (type) => {
    switch (type) {
    case 'profile':
      return tc('common.profile');
    case 'template':
      return tc('common.template');
    case 'space':
      return tc('common.space');
    default:
      return type;
    }
  };

  const changeFilter = (choice) => {
    selectedFilter.value = choice;
  };

  const chooseLabel = (id) => {
    labelId.value = id;
    show.value.delete = true;
  };

  const getLabelsHandler = async (type) => {
    try {
      const labels = await getLabels(type);
      const current = allLabels.value;
      allLabels.value = [...current, ...labels];
    } catch (e) {
      $snack.error(e.message);
    }
  };

  const createLabelHandler = loadingWrapper(async (label) => {
    const newLabel = await createLabel(
      label.name,
      label.color,
      selectedFilter.value
    );
    allLabels.value.push(newLabel);
  }, loading);

  const deleteLabelHandler = loadingWrapper(async () => {
    await deleteLabel(labelId.value);
    allLabels.value = allLabels.value.filter(
      (label) => label._id !== labelId.value
    );
  }, loading);
</script>

<style lang="scss">
.workspace-labels {
  &__create-label {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__list {
    margin-top: 8px;
    display: grid;
    gap: 4px;

    &_disabled {
      opacity: 0.5;
    }
  }

  &__empty-list {
    text-align: center;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
