import { useHttp } from '@/middleware/httpMiddleware';

/**
 * @param {string} userPrompt
 * @param {string} actionId
 * @param {string} textToRework
 */
async function generatePrompt (userPrompt = '', actionId = '', textToRework = '') {
  const data = await useHttp(
    '/ai/generate/campaign_text',
    'POST',
    {
      goal: userPrompt,
      text_to_rework: textToRework,
      request_id: actionId,
    },
    null,
    true
  );

  return data.payload;
}

/**
 * @param {string} goal
 * @param {string} profileId
 * @param {string} credentialId
 * @param {'email' | 'linkedin' | 'call' | 'sms'} messageType
 * @param {string} requestIdSuffix
 * @param {string} textToRework
 */
async function generatePromptInbox (goal = '', profileId = '', credentialId = '', messageType = 'linkedin', requestIdSuffix = '', textToRework = '') {
  const data = await useHttp(
    '/ai/generate/inbox_message',
    'POST',
    {
      goal,
      text_to_rework: textToRework,
      profile_id: profileId,
      credential_id: credentialId,
      message_type: messageType,
      request_id: profileId + requestIdSuffix,
    },
    null,
    true
  );

  return data.payload;
}

async function getRecentCampaignGoals () {
  const data = await useHttp(
    '/ai/recent_campaign_goals',
    'GET'
  );

  return data.payload;
}

export {
  generatePrompt,
  generatePromptInbox,
  getRecentCampaignGoals
};
