<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="workflow-step continue-step"
    :class="[{'continue-step_active primary-border':active}, {'continue-step_dissable': dissable}]"
    @click="showAction = true"
  >
    <div class="workflow-step__header">
      <p
        :class="[
          'workflow-step__number  primary-bg text',
        ]"
      >
        {{ `${index + 1}` }}
      </p>
      <div
        class="workflow-step__title"
      >
        <span class="workflow-step__title-text">
          {{ title }}
        </span>
      </div>
    </div>
    <div class="workflow-step__content">
      <info-item :info="info" />
    </div>
  </div>
</template>
<script>
  // import { EyeIcon } from 'vue-feather-icons';
  import PerformActionItem from '@/components/updateWorkflow/performAction/PerformActionItem.vue';
  export default {
    name: 'ContinueStep',
    // components: { EyeIcon },
    extends: PerformActionItem,
    props: {
      action: {
        type: Object,
        default: null,
      },
      index: {
        type: Number,
        default: null,
      },
      active: {
        type: Boolean,
        default: false,
      },
      dissable: {
        type: Boolean,
        default: false,
      },
    },
    mounted () {
      this.setDefault();
    },
    methods: {
      clickFade () {
        this.$noty.show('To edit this campaign visit campaign settings page');
      },
      showMore () {
        this.showAction = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/components/updateWorkflow/styles/workflowStep.scss';
  .continue-step {
    flex-basis: calc(50% - 15px);
    border: 2px solid transparent;
    padding: 0px 20px 16px 30px;
    display: block;
    height: 130px;
    &__fade {
      width: 100%;
      height: calc(100% - 65px);
      bottom: 0;
      left: 0;
      z-index: 2;
      position: absolute;
    }

    &_active {
      border: 2px solid $primary-cl;
    }
    &_dissable {
      opacity: 0.5;
    }
  }
</style>
