<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      :key="key"
      :name="name"
      width="900px"
      height="auto"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="workflow-step-edit">
        <header class="workflow-step-edit__header">
          <div class="workflow-step-edit__info">
            <span
              class="workflow-step-edit__text workflow-step-edit__text_title text_xs color_white"
            >
              <i
                class="bx"
                :class="getActionIcon()"
              />
              {{ title }}
            </span>
            <span
              v-if="$route.name !== 'smart-sequences'"
              class="workflow-step-edit__text text_xs color_light-secondary"
            >
              {{ $t('campaignsPage.editor.steps.modals.stepEdit.stepCount', { count: index + 1 }) }}
            </span>
            <default-button
              class="modal__close-button"
              color="neutral"
              form="circle"
              size="m"
              icon-name="bx-x"
              @action="handleClose"
            />
          </div>
        </header>
        <div
          class="workflow-step-edit__main-container"
          :class="{
            'workflow-step-edit__main-container_sequence':
              $route.name === 'smart-sequences',
          }"
        >
          <section>
            <component
              :is="getActionComponent()"
              :actions="updatedActions"
              :video="video"
              :template="template"
              :status.sync="statusConnect"
              :is-sales.sync="isSales"
              :read-only="!!action.template"
              :is-email-only="isEmailOnly"
              :is-wait-accept.sync="isWaitAccept"
              :type.sync="emailType"
              :phone-type.sync="phoneType"
              :integration-id="integrationId"
              :vox-phone.sync="voxPhone"
              :subject.sync="subject"
              :message.sync="message"
              :parent-thread.sync="parentThread"
              :subject-length.sync="subjectLength"
              :message-length.sync="messageLength"
              :message-error="errors.messageError"
              :subject-error="errors.subjectError"
              :email-threading="emailThreading"
              :crm-owner.sync="crmOwner"
              :crm.sync="crm"
              :action-id="actionId"
              :brand="user.config.name"
              :is-ai-message-enabled="isAiMessageEnabled"
              :manual-task-settings="manualTaskSettings"
              :is-need-manual-approve="isNeedManualApprove"
              @update:integration-id="integrationId = $event"
              @update:is-ai-message-enabled="isAiMessageEnabled = $event"
              @update:manual-task-settings="updateManualTaskSettings"
              @update:is-need-manual-approve="isNeedManualApprove = $event"
              @update:max-subject-length="maxSubjectLength = $event"
              @update:max-message-length="maxMessageLength = $event"
              @change-actions="changeActions"
              @choose-template="modals.templates = true"
              @unlink-template="unlinkTemplate"
              @edit-template="editTemplate"
              @choose-video="openVideoModal"
              @remove-video="removeVideo"
              @insert-video="insertVideo"
            />
          </section>
          <footer class="workflow-step-edit__footer">
            <div class="flex-row flex-justify-content-between">
              <delay-counter
                :delay_days.sync="delayDays"
                :delay_hours.sync="delayHours"
              />

              <div class="workflow-step-edit__btn-container">
                <default-button
                  v-if="
                    action.type === 'send_email' || action.type === 'send_webhook'
                  "
                  size="m"
                  color="neutral"
                  :title="$t('campaignsPage.editor.steps.modals.stepEdit.buttons.sendTest')"
                  form="rectangle"
                  @action="sendTest(action.type)"
                />
                <default-button
                  size="m"
                  color="primary"
                  :title="$t('campaignsPage.editor.steps.modals.stepEdit.buttons.saveStep')"
                  form="rectangle"
                  @action="saveAction"
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
      <videos-modal
        :show.sync="modals.videos"
        @choose-video="insertVideo"
        @close-modal="modals.videos = false"
      />
      <load-template-modal
        :show.sync="modals.templates"
        :type="action.type"
        @send="selectTemplate"
      />
      <input-modal
        name="SendTestEmail"
        title="Send Test Email"
        primary-button-text="Send"
        :tags.sync="recentlyUsedEmails"
        :show.sync="showSendTestEmail"
        :placeholder="$tc('common.email')"
        height="auto"
        @send="sendTestEmail"
      />
    </modal>
  </portal>
</template>
<script>
  import { templatesMixin } from '@/mixins/templatesMixin';
  import { usedEmailsMixin } from '@/mixins/usedEmailsMixin';
  import { mapGetters } from 'vuex';
  import ManualMenu from '@/components/updateWorkflow/performAction/manualMenu/ManualMenu.vue';
  import DelayCounter from '@/components/updateWorkflow/performAction/DelayCounter.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import ConnectionRequest from '@/components/updateWorkflow/performAction/editActions/ConnectionRequest';
  import PerformAction from '@/components/updateWorkflow/performAction/editActions/PerformAction';
  import SendMessageStep from '@/components/updateWorkflow/performAction/editActions/SendMessageStep';
  import SendEmailStep from '@/components/updateWorkflow/performAction/editActions/SendEmailStep';
  import SendInmailStep from '@/components/updateWorkflow/performAction/editActions/SendInmailStep';
  import WebhookStep from '@/components/updateWorkflow/performAction/editActions/WebhookStep';
  import InviteToEvent from '@/components/updateWorkflow/performAction/editActions/InviteToEvent';
  import EnrichProfileStep from '@/components/updateWorkflow/performAction/editActions/EnrichProfileStep';
  import PushToCrm from '@/components/updateWorkflow/performAction/editActions/PushToCrm';
  import EmailConnectionRequest from '@/components/updateWorkflow/performAction/editActions/EmailConnectionRequest';
  import ManualTask from '@/components/updateWorkflow/performAction/editActions/ManualTask';
  import MakeCall from '@/components/updateWorkflow/performAction/editActions/MakeCall';
  import SendSms from '@/components/updateWorkflow/performAction/editActions/SendSms';
  import VideosModal from '@/components/videoEditor/VideosModal';
  import LoadTemplateModal from '@/modals/LoadTemplateModal.vue';
  import InputModal from '@/modals/inputModal.vue';
  import { sendTestEmail } from '@/api/workflowsMethods';
  import { testWebhook } from '@/api/webhooksMethods';
  import { cloneDeep } from 'lodash';
  import { regexpSearch } from '@/components/updateWorkflow/addSearch/utils';

  const urlRegex =
    /^(?!mailto:)(?:(?:http|https|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[0-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?-)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))|localhost)(?::\d{2,5})?(?:(\/|\?|#)[^\s]*)?$/;
  export default {
    name: 'WorkflowStepEditModal',
    components: {
      DefaultButton,
      DelayCounter,
      ConnectionRequest,
      WebhookStep,
      SendInmailStep,
      SendEmailStep,
      PerformAction,
      SendMessageStep,
      EnrichProfileStep,
      EmailConnectionRequest,
      PushToCrm,
      ManualTask,
      MakeCall,
      SendSms,
      VideosModal,
      LoadTemplateModal,
      InputModal,
      ManualMenu,
      InviteToEvent,
    },
    mixins: [templatesMixin, usedEmailsMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      action: {
        type: Object,
        default: () => ({}),
      },
      name: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: 0,
      },
      isEmailOnly: {
        type: Boolean,
        default: false,
      },
      emailThreading: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        key: this.name,
        actionId: this.action.id,
        delayDays: this.action.delay_days,
        delayHours: this.action.delay_hours,
        message: this.action.text_template || this.action.webhook_link || this.action.event_link || '',
        subject: this.action.subject_template,
        isSales: this.action.is_sales,
        emailType: this.action.email_type,
        phoneType: this.action.phone_type,
        crmOwner: this.action.crm_owner_pk || '',
        crm: this.action.crm_platform || '',
        isWaitAccept: this.action.is_wait_accept,
        parentThread: this.action?.parentThread || '',
        isAiMessageEnabled: Boolean(this.action?.openai_message_mode),
        isNeedManualApprove: this.action?.is_need_approve || false,
        integrationId: '',
        voxPhone: this.action?.vox_phone || '',
        showSendTestEmail: false,
        updatedActions: [],
        statusConnect: false,
        messageLength: 0,
        maxMessageLength: Infinity,
        subjectLength: 0,
        maxSubjectLength: Infinity,
        manualTaskSettings: this.action?.manual_task_settings || {},
        update: {},
        video: null,
        template: {},
        errors: {
          messageError: {
            warningText: '',
            errorText: '',
          },
          subjectError: {
            warningText: '',
            errorText: '',
          },
        },
        modals: {
          templates: false,
          videos: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
        selectedCredential: 'credentials/selectedCredential',
        scenarioList: 'scenario/scenarioList',
        workflow: 'workflows/workflow',

      }),
    },
    watch: {
      show (show) {
        show ? this.$modal.show(this.name) : this.$modal.hide(this.name);
        if (!show) {
          this.key = `${this.key} ${new Date().getTime()}`;
          this.resetErrorsState();
        } else {
          this.checkUpdate();
          this.checkMessageError();
        }
      },
      messageLength () {
        this.checkMessageError();
      },
      subjectLength () {
        this.checkMessageError();
      },
      delayDays (value) {
        this.updateAction({ delay_days: value });
        if (this.action.min_delay_hours > this.delayHours && !this.delayDays) {
          this.delayHours = this.action.min_delay_hours;
        }
      },
      delayHours (value) {
        if (
          !this.action.min_delay_hours ||
          this.delayDays ||
          value >= this.action.min_delay_hours
        ) {
          this.updateAction({ delay_hours: value });
        } else {
          this.delayHours = this.action.min_delay_hours;
          this.$noty.error(
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.minimumDelay', {
              delay: this.action.min_delay_hours,
            })
          );
        }
      },
      isAiMessageEnabled () {
        this.updateAction({ openai_message_mode: this.isAiMessageEnabled ? 'personalized' : null });
      },
      isNeedManualApprove () {
        this.updateAction({ is_need_approve: this.isNeedManualApprove });
      },
      message (newMessage) {
        this.checkMessageError(newMessage);
        this.updateVideo();
      },
      emailType () {
        this.updateAction({ email_type: this.emailType });
      },
      phoneType () {
        this.updateAction({ phone_type: this.phoneType });
      },
      integrationId () {
        this.updateAction({ integration_id: this.integrationId });
      },
      voxPhone () {
        this.updateAction({ vox_phone: this.voxPhone });
      },
      subject () {
        this.updateAction({ subject_template: this.subject });
        this.checkSubjectError(this.subject, 'subjectError');
      },
      parentThread () {
        this.updateAction({ parentThread: this.parentThread });
      },
      isSales () {
        this.updateAction({ is_sales: this.isSales });
        this.checkSubjectError(this.subject, 'subjectError');
        this.checkMaxTextLength();
      },
      isWaitAccept () {
        this.updateAction({ is_wait_accept: this.isWaitAccept });
      },
      template (newTemplate) {
        this.update.template = newTemplate?._id || '';
      },
      video (newVideo) {
        this.update.video_scenario = newVideo?._id || '';
      },
      crmOwner () {
        this.updateAction({ crm_owner_pk: this.crmOwner });
      },
      crm () {
        this.updateAction({ crm_platform: this.crm });
      },
    },

    methods: {
      resetErrorsState () {
        this.errors = {
          messageError: { warningText: '', errorText: '' },
          subjectError: { warningText: '', errorText: '' },
        };
      },
      updateManualTaskSettings (settings) {
        Object.assign(this.manualTaskSettings, settings);
        this.updateAction({ manual_task_settings: this.manualTaskSettings });
      },
      handleClose () {
        this.$emit('update:show', false);
      },
      checkUpdate () {
        this.actionId = this.action.id;
        this.delayDays = this.action.delay_days;
        this.delayHours = this.action.delay_hours;
        this.updatedActions = this.action.actions;
        this.update = cloneDeep(this.action);
        this.message =
          this.update.text_template || this.update.webhook_link || this.update.event_link || '';
        this.subject = this.update.subject_template;
        this.crmOwner = this.action.crm_owner_pk;
        this.crm = this.action.crm_platform;
        this.isSales = this.update.is_sales;
        this.emailType = this.update.email_type;
        this.phoneType = this.update.phone_type || null;
        this.integrationId = this.action.integration_id || '';
        this.voxPhone = this.action.vox_phone || '';
        this.isWaitAccept = this.action.is_wait_accept;
        this.parentThread = this.action.parentThread || '';
        this.template = this.action.template_object || null;
        this.isNeedManualApprove = this.action?.is_need_approve || false;
        this.manualTaskSettings = this.action.manual_task_settings || {};
        if (this.action.video_scenario) {
          this.video = this.scenarioList.find(
            (scenario) => scenario._id === this.action.video_scenario
          );
        }
      },
      changeActions (type) {
        let actions;
        if (this.update?.actions) {
          actions = this.update.actions.slice(0);
        } else {
          actions = this.action.actions.slice(0);
        }

        if (actions.includes(type)) {
          const typeIndex = actions.indexOf(type);
          actions.splice(typeIndex, 1);
        } else {
          actions.push(type);
        }
        this.updateAction({ actions });

        this.update.actions = actions;
        this.$emit('update-action', this.update);
      },
      getActionComponent () {
        switch (this.action.type) {
        case 'li_actions':
          return 'PerformAction';
        case 'li_connect':
          return 'ConnectionRequest';
        case 'send_webhook':
          return 'WebhookStep';
        case 'li_invite_event':
          return 'InviteToEvent';
        case 'li_send_message':
          return 'SendMessageStep';
        case 'send_email':
          return 'SendEmailStep';
        case 'li_send_inmail':
          return 'SendInmailStep';
        case 'enrich_profile':
          return 'EnrichProfileStep';
        case 'li_connect_by_email':
          return 'EmailConnectionRequest';
        case 'push_to_crm':
          return 'PushToCrm';
        case 'manual_task':
          return 'ManualTask';
        case 'manual_call':
          return 'MakeCall';
        case 'send_sms':
          return 'SendSms';
        }
      },
      getActionIcon () {
        switch (this.action.type) {
        case 'li_actions':
          return 'bxs-user-detail';
        case 'li_connect':
          return 'bx-user-plus';
        case 'send_webhook':
          return 'bx-universal-access';
        case 'li_invite_event':
          return 'bx-calendar-event';
        case 'li_send_message':
          return 'bx-message-detail';
        case 'send_email':
          return 'bx-mail-send';
        case 'li_send_inmail':
          return 'bx-edit';
        case 'enrich_profile':
          return 'bx-diamond';
        case 'li_connect_by_email':
          return 'bx-user-pin';
        case 'push_to_crm':
          return 'bx-line-chart';
        case 'manual_task':
          return 'bx-calendar-check';
        case 'manual_call':
          return 'bx-phone';
        case 'send_sms':
          return 'bx-message-square-dots';
        }
      },
      updateAction (update) {
        Object.keys(update).forEach((key) => {
          this.update[key] = update[key];
        });
        if (this.action.type === 'li_actions' && update.actions) {
          this.updatedActions = update.actions;
        }
      },
      updateError () {
        const { messageError, subjectError } = this.errors;
        let errorText =
          messageError.warningText ||
          messageError.errorText ||
          subjectError.errorText ||
          subjectError.warningText;

        if (
          errorText.includes(
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.fallbackWarningPersists')
          )
        ) {
          errorText = this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.checkCurlyBrackets');
        }

        this.updateAction({ error: errorText });
        this.$emit('update-error', errorText);
      },
      saveAction () {
        this.checkSubjectError(this.subject, 'subjectError');
        if (
          this.errors.messageError.errorText ||
          this.errors.subjectError.errorText
        ) {
          this.$noty.error(
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.resolveErrors')
          );
        } else {
          this.updateError();
          this.$emit('update-action', this.update);
          this.handleClose();
          if (Object.keys(this.update).length) {
            this.$noty.success(
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.updated')
            );
          }
          this.update = {};
        }
      },
      checkSubjectError (subject) {
        if (this.action.type === 'li_send_inmail') {
          if (!subject.length && this.isSales) {
            this.errors.subjectError.errorText =
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.subjectRequired');
          }
        }
        this.checkTextByPattern(subject);
      },
      checkMaxTextLength (errorType = 'messageError') {
        switch (errorType) {
        case 'messageError':
          if (this.action.type === 'li_send_inmail' && this.isSales && (this.messageLength > this.maxMessageLength)) {
            this.errors.messageError.errorText =
              'You have exceeded the maximum message character limit';
          } else if (this.action.type !== 'li_send_inmail' && this.messageLength > this.maxMessageLength) {
            this.errors.messageError.errorText =
              'You have exceeded the maximum message character limit';
          } else {
            this.errors.messageError.errorText = '';
          }
          break;
        case 'subjectError':
          if (this.subjectLength > this.maxSubjectLength) {
            this.errors.subjectError.errorText =
              'You have exceeded the maximum subject character limit';
          } else {
            this.errors.subjectError.errorText = '';
          }
          break;
        }
      },
      checkMessageError () {
        if (
          this.action.type !== 'send_email' &&
          !this.message.match(/\{video \|[^}]+\}/g)
        ) {
          this.video = null;
          this.updateAction({ video_scenario: null });
        }
        if (this.action.type === 'send_webhook') {
          this.updateAction({ webhook_link: this.message });
        } else if (this.action.type === 'li_invite_event') {
          this.updateAction({ event_link: this.message });
        } else {
          this.updateAction({ text_template: this.message });
        }
        switch (this.action.type) {
        case 'li_connect':
          this.checkMaxTextLength();
          this.checkTextByPattern(this.message);
          break;
        case 'li_send_message':
          this.checkTextByPattern(this.message);
          if (!this.message.length) {
            this.errors.messageError.errorText =
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.emptyMessage');
          } else {
            this.errors.messageError.errorText = '';
          }
          this.checkMaxTextLength();
          break;
        case 'li_send_inmail':
          this.checkTextByPattern(this.message);
          if (!this.message.length) {
            this.errors.messageError.errorText =
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.emptyMessage');
          } else {
            this.errors.messageError.errorText = '';
          }
          this.checkMaxTextLength();
          this.checkMaxTextLength('subjectError');
          break;
        case 'send_webhook':
          this.checkURL(this.message);
          break;
        case 'li_invite_event':
          this.checkEventLink(this.message);
          break;
        case 'send_email':
          if (!this.message.match(/<p>\{video \|[^}]+\}<\/p>/g)) {
            this.video = null;
            this.updateAction({ video_scenario: null });
          }
          this.checkTextByPattern(this.message);
          // eslint-disable-next-line no-case-declarations
          const message = new DOMParser()
            .parseFromString(this.message, 'text/html')
            .querySelector('body')
            .innerText.trim();

          if (message.length < 1) {
            this.errors.messageError.errorText =
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.emptyMessage');
          } else {
            this.errors.messageError.errorText = '';
          }
          break;
        case 'send_sms':
          this.checkMaxTextLength();
          break;
        }
      },
      checkTextByPattern (text, errorObjectName = 'messageError') {
        const words = text ? text.split(/(?=[{}])/) : [];

        // Следит, чтобы слова в фигурных скобках соответствовали паттерну
        const wordsNotFit = words.filter((word, index) => {
          if (
            index !== words.length - 1 &&
            word.charAt(0) === '{' &&
            words[index + 1].charAt(0) === '}'
          ) {
            word = word.trim() + '}';
            return word.includes('FALLBACK');
          }
          return false;
        });
        if (wordsNotFit.length) {
          this.errors[errorObjectName].warningText =
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.replaceFallback');
        } else {
          this.errors[errorObjectName].warningText = '';
        }
      },
      openVideoModal () {
        if (!this.isEmailOnly) {
          this.modals.videos = true;
        } else {
          this.$noty.error(
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.videoToEmail')
          );
        }
      },
      insertVideo (video) {
        this.modals.videos = false;
        this.video = video;
        if (this.action.type !== 'send_email') {
          this.message =
            this.message.replace(/\{video \|[^}]+\}/g, '') +
            ` {video | ${video.name}}`;
        } else {
          this.message =
            this.message.replace(/<p>\{video \|[^}]+\}/g, '') +
            `<p>{video | ${video.name}}</p>`;
        }
        this.updateAction({
          text_template: this.message,
          video_scenario: video._id,
        });
      },
      removeVideo (video) {
        if (this.action.type !== 'send_email') {
          this.message = this.message.replace(`{video | ${video.name}}`, ' ');
        }
        this.video = null;
        this.updateAction({ text_template: this.message, video_scenario: null });
      },
      updateVideo () {
        const videoPattern = this.message.match(/{video \|[^}]+\}/g);
        if (videoPattern) {
          const videoName = videoPattern[0].replace('{video | ', '').slice(0, -1);
          const video = this.scenarioList.find(
            (video) => video.name === videoName
          );
          this.video = video;
          if (!video) {
            this.errors.messageError.errorText =
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.wrongVideoPattern');
          }
          if (videoPattern.some((name) => name !== videoPattern[0])) {
            this.errors.messageError.errorText =
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.onlyOneVideoPattern');
          }
        } else if (this.video) {
          this.video = null;
        }
      },
      selectTemplate (template) {
        this.template = template;
        this.modals.templates = false;
        this.video = null;
        this.$emit('update-action', {
          template: template._id,
          video_scenario: null,
        });
      },
      unlinkTemplate () {
        this.template = null;
        this.$emit('update-action', { template: '' });
      },
      editTemplate (template) {
        this.$router.push({
          name: 'my-templates',
          query: {
            ...this.$route.query,
            template: template?._id,
          },
        });
      },
      checkURL (url, errorObjectName = 'messageError') {
        if (!urlRegex.test(url)) {
          this.errors[errorObjectName].errorText =
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.wrongWebhookUrl');
        } else {
          this.errors[errorObjectName].errorText = '';
        }
      },
      checkEventLink (url, errorObjectName = 'messageError') {
        if (!regexpSearch.eventRegex.test(url)) {
          this.errors[errorObjectName].errorText =
            this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.wrongEventUrl');
        } else {
          this.errors[errorObjectName].errorText = '';
        }
      },
      async sendTestEmail (email) {
        try {
          await sendTestEmail(
            email,
            this.subject,
            this.integrationId,
            this.message,
            this.selectedCredential._id,
            this.workflow?._id
          );
          this.showSendTestEmail = false;
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async testWebhook () {
        try {
          if (!urlRegex.test(this.targetUrl)) {
            const code = Number(
              await testWebhook(
                this.message,
                'invite_sent',
                this.selectedCredential._id
              )
            );
            if (code / 100 === 2) {
              this.$noty.success(
                this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.webhookRespond', {
                  code,
                })
              );
            } else {
              this.$noty.error(
                this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.webhookRespond', {
                  code,
                })
              );
            }
          } else {
            this.$noty.error(
              this.$t('campaignsPage.editor.steps.modals.stepEdit.notifications.checkUrl')
            );
          }
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async sendTest (type) {
        if (type === 'send_webhook') {
          await this.testWebhook();
        } else this.showSendTestEmail = true;
      },
    },
  };
</script>

<style lang="scss">
.workflow-step-edit {
  background: var(--primary-cl);
  border-radius: 12px;
  padding: 0 4px 4px;
  border-top: none;

  &__header {
    border-radius: 8px 8px 0px 0px;
    background: var(--primary-cl);
    padding: 8px 8px 12px;
    margin-bottom: -8px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
  }
  &__main-container {
    background: var(--table-bg);
    border-radius: 8px;
    box-shadow: 0 8px 32px rgba(10, 32, 51, 0.16);
    max-height: calc(100vh - 50px);
    overflow: auto;
    &_sequence {
      display: flex;
      flex-flow: column;
      max-height: 90vh;
      overflow: scroll;
    }
  }
  &__footer {
    padding: 0px 16px 24px;
  }
  &__btn-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    align-self: end;
  }
  &__close-button {
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
  }
  &__text {
    display: flex;
    gap: 4px;
    align-items: center;
    &_title {
      text-transform: uppercase;
      .bx {
        font-size: 16px;
      }
    }
  }
}
</style>
