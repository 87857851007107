<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="generate-prompt-inbox">
    <default-tooltip
      :show="isTooltipShown && Boolean(recentGoals.length)"
      :items="recentGoals"
      :is-disabled="true"
      :has-triangle="false"
      list-max-width="100%"
      @update:show="onTooltipShowUpdate"
      @select="onTooltipSelect"
    >
      <default-input-with-button
        :text-input="inputPrompt"
        :buttons-group-data="buttonsGroupData"
        :message="message"
        :placeholder="$t('placeholders.typeYourPromptInbox')"
        :is-loading="isLoading"
        @get-option="getSwitchPrompt"
        @action="generateText"
        @update:text-input="onTextInputUpdate"
        @focus="onInputFocus"
      />
    </default-tooltip>
  </div>
</template>

<script setup>
  // GeneratePromptSteps
  import { ref, onBeforeUnmount, onMounted, onBeforeMount } from 'vue';
  import DefaultTooltip from '@/components/base/uiKit.v3//DefaultTooltip.vue';
  import DefaultInputWithButton from '@/components/base/uiKit.v3/DefaultInputWithButton.vue';
  import { useI18n } from '@/i18n';
  import { useSnack } from '@/lib/useSnack';
  import { generatePrompt, getRecentCampaignGoals } from '@/api/AIMethods';
  import { useSocketIo } from '@/use/socketIo';
  const { sockets, setResponseTimeoutError, resetTimeout } = useSocketIo();

  const { $snack } = useSnack();
  const { t } = useI18n();

  const emit = defineEmits(['update:generate']);
  const props = defineProps({
    message: {
      type: String,
      default: '',
    },
    actionId: {
      type: String,
      default: '',
    },
  });

  // #region Recent goals
  const recentGoals = ref([]);

  onBeforeMount(async () => {
    await setRecentGoals();
  });

  async function setRecentGoals () {
    /**
     * @type {string[]}
     */
    const recentGoalsResponse = JSON.parse(await getRecentCampaignGoals());

    if (recentGoalsResponse.length) {
      recentGoals.value = [];

      recentGoals.value.push({
        isTitle: true,
        name: 'Recents',
        value: 'recents',
        icon: {
          name: 'bx-time',
        },
      });

      recentGoalsResponse.forEach((goal, idx) => {
        recentGoals.value.push({
          name: goal,
          value: 'goal_' + idx,
          icon: {
            name: 'bxs-magic-wand',
            color: 'color_tertiary',
          },
        });
      });
    }
  }
  // #endregion

  // #region Tooltip
  const isTooltipShown = ref(false);

  function onTooltipShowUpdate (isShown) {
    isTooltipShown.value = isShown;
  }

  function onTooltipSelect (item) {
    inputPrompt.value = item.name;
  }

  function showTooltip () {
    isTooltipShown.value = true;
  }

  function hideTooltip () {
    isTooltipShown.value = false;
  }
  // #endregion

  // #region Input
  const inputPrompt = ref('');

  function onTextInputUpdate (prompt) {
    inputPrompt.value = prompt;

    if (prompt.length > 0) {
      hideTooltip();
    } else {
      showTooltip();
    }
  }

  function onInputFocus () {
    showTooltip();
  }
  // #endregion

  const isLoading = ref(false);

  const buttonsGroupData = ref([
    {
      value: 'recreate',
      name: t('campaignsPage.editor.steps.modals.stepEdit.buttons.recreatePrompt'),
      loadingName: 'Recreating...',
      icon: {
        name: 'bx-revision',
        color: 'color_tertiary',
      },
    },
    {
      value: 'refine',
      name: t('campaignsPage.editor.steps.modals.stepEdit.buttons.refinePrompt'),
      loadingName: 'Refining...',
      icon: {
        name: 'bxs-magic-wand',
        color: 'color_tertiary',
      },
    },
  ]);

  const handleGeneratePrompt = async (userPrompt, actionId, message = '') => {
    if (!userPrompt) return;
    isLoading.value = true;
    try {
      await generatePrompt(userPrompt, actionId, message);
      handleResponseTimeoutError();
    } catch (e) {
      $snack.error(e.message);
    }
  };

  const generateText = async () => {
    hideTooltip();
    await handleGeneratePrompt(inputPrompt.value, props.actionId);
  };

  const refinePrompt = async () => {
    hideTooltip();
    await handleGeneratePrompt(inputPrompt.value, props.actionId, props.message);
  };

  const getSwitchPrompt = async (type) => {
    switch (type?.value) {
    case 'refine':
      await refinePrompt();
      return;
    default:
      await generateText();
    }
  };

  const generatedResponse = ref(null);

  const handleResponseTimeoutError = () => {
    setResponseTimeoutError(
      () => {
        isLoading.value = false;
      }
    );
  };

  onMounted(async () => {
    sockets.subscribe('generated_data', async (response) => {
      const res = JSON.parse(response);
      if (res?.request_id !== props.actionId) return;
      resetTimeout();
      isLoading.value = false;
      inputPrompt.value = '';
      generatedResponse.value = res.payload?.message;
      emit('update:generate', res.payload?.message);
      await setRecentGoals();
    });
  });

  onBeforeUnmount(() => {
    sockets.unsubscribe('generated_data');
  });
</script>
