<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="mb-2">
    <div class="manual-menu flex flex-gap-8">
      <default-button-tooltip
        :items="followUpOptions || []"
        class="manual-menu__button-shadow"
        data-closable="manualStepCalendar"
        icon-name="bx-calendar"
        size="s"
        active-color="neutral"
        color="transparent"
        max-height="180"
        title-color="color_black"
        border-color="neutral-300_05"
        :title="selectedDate?.name"
        @choose="selectDate"
      >
        <template #firstItemList>
          <li class="uikit-tooltip__item button-tooltip-list__first-item color_tertiary text_s">
            {{ $t('common.followUp') }}
          </li>
        </template>
      </default-button-tooltip>

      <default-button-tooltip
        :items="users"
        class="manual-menu__button-shadow"
        data-closable="manualStepAssignee"
        :icon-name="selectedUser?.img ? '' : 'bx-user'"
        :btn-img="selectedUser?.img || ''"
        size="s"
        active-color="neutral"
        color="transparent"
        border-color="neutral-300_05"
        max-height="180"
        title-color="color_black"
        :title="selectedUser?.name"
        @choose="selectUser"
      >
        <template #firstItemList>
          <li class="uikit-tooltip__item button-tooltip-list__first-item color_tertiary text_s">
            {{ $tc('common.user', 2) }}
          </li>
        </template>
      </default-button-tooltip>

      <default-button-tooltip
        :items="priorities"
        class="manual-menu__button-shadow"
        :icon-color="selectedPriority?.iconColor"
        data-closable="manualStepPriority"
        icon-name="bx-flag"
        size="s"
        active-color="neutral"
        color="transparent"
        max-height="180"
        title-color="color_black"
        border-color="neutral-300_05"
        :title="selectedPriority?.name"
        @choose="selectPriority"
      />
    </div>

    <manual-menu-info class="mt-2" />
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import ManualMenuInfo from './ManualMenuInfo.vue';
  import DefaultButtonTooltip from '@/components/base/uiKit.v3/DefaultButtonTooltip.vue';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n';
  import { PRIORITY, PRIORITY_COLORS } from '@/data/priority';
  import { followUpOptions } from '@/data/date';

  const props = defineProps({
    manualTaskSettings: {
      type: [Object, null],
      default: null,
    },
  });

  const emit = defineEmits(['update:manual-task-settings']);

  const store = useStore();

  const { tc } = useI18n();

  const account = computed(() => store.getters['account/account']);
  const currentUser = computed(() => store.getters['user/user']);

  // #region Date
  const selectedDate = ref(followUpOptions.value?.find((date) => {
    return date.value === props.manualTaskSettings?.follow_up;
  }));

  const selectDate = (date) => {
    selectedDate.value = date;
    emit('update:manual-task-settings', { follow_up: date.value });
  };

  onMounted(() => {
    if (!selectedDate.value) {
      selectedDate.value = followUpOptions.value[0];
      emit('update:manual-task-settings', { follow_up: selectedDate.value.value });
    }
  });
  // #endregion

  // #region Assignee
  const users = computed(() => {
    return account.value.users.map(user => {
      return {
        name: user.full_name,
        value: user._id,
        img: user.avatar,
      };
    });
  });

  const selectedUser = ref(users.value?.find((user) => {
    return user.value === props.manualTaskSettings?.assignee;
  }));

  const selectUser = (user) => {
    selectedUser.value = user;
    emit('update:manual-task-settings', { assignee: user.value });
  };

  onMounted(() => {
    if (!selectedUser.value) {
      selectedUser.value = {
        name: currentUser.value.full_name,
        value: currentUser.value._id,
        img: currentUser.value.avatar,
      };
      emit('update:manual-task-settings', { assignee: selectedUser.value.value });
    }
  });
  // #endregion

  // #region Priority
  const priorities = ref(Object.entries(PRIORITY.value).map(([key, value]) => {
    return {
      name: value,
      value: Number(key),
      iconName: 'bx-flag',
      iconColor: PRIORITY_COLORS[key],
    };
  }).sort((a, b) => b.value - a.value));

  const selectedPriority = ref(priorities.value?.find((priority) => {
    return priority.value === props.manualTaskSettings?.priority;
  }));

  const selectPriority = (priority) => {
    selectedPriority.value = priority;
    emit('update:manual-task-settings', { priority: priority.value });
  };

  onMounted(() => {
    if (!selectedPriority.value) {
      selectedPriority.value = priorities.value.find((_priority) => {
        return _priority.value === 100;
      });
      emit('update:manual-task-settings', { priority: selectedPriority.value.value });
    }
  });
  // #endregion
</script>

<style lang="scss">
.manual-menu {
  &__button-shadow {
    box-shadow: 0 1px 2px rgba(35, 44, 96, 0.12);
    border-radius: 4px;
  }

  .button-tooltip-list {
    &__first-item {
      pointer-events: none;
      cursor: default;
    }
  }
}
</style>
