import { computed } from 'vue';
import i18n from '@/i18n';

export const followUpOptions = computed(() => [
  {
    name: i18n.t('date.sameDay'),
    value: 'days_0',
  },
  {
    name: i18n.t('date.followUp.inOneDay'),
    value: 'days_1',
  },
  {
    name: i18n.t('date.followUp.inTwoDays'),
    value: 'days_2',
  },
  {
    name: i18n.t('date.followUp.inThreeDays'),
    value: 'days_3',
  },
  {
    name: i18n.t('date.followUp.inOneWeek'),
    value: 'weeks_1',
  },
  {
    name: i18n.t('date.followUp.inTwoWeeks'),
    value: 'weeks_2',
  },
  {
    name: i18n.t('date.followUp.inOneMonth'),
    value: 'months_1',
  },
  {
    name: i18n.t('date.followUp.inThreeMonths'),
    value: 'months_3',
  },
  {
    name: i18n.t('date.followUp.inSixMonths'),
    value: 'months_6',
  },
]);
